import React, { FunctionComponent, useEffect, useState } from 'react';
import '../../../components/functionalComponents/DashboardLayout/style.css';
import FooterMenu from '../../../components/functionalComponents/DashboardLayout/FooterMenuAccess';
import { Container, Row, Col, Spinner, Form, Button, Alert, Image, Nav, Navbar, Modal, InputGroup, FormControl } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import VerificationNotice from '../../../components/loginForm/VerificationNotice';
import SessionService from '../../../services/SessionsService';
import Logo from '../../../assets/img/AVAP_ID_horizontal.png';
import Logo101Obex from '../../../assets/img/newDashboard/icono_Framework.png';
import iconosalida from '../../../assets/img/newDashboard/Icon ion-close-circle-outline.png';
import logo_framework from '../../../assets/img/avap_logo.png'

import info from '../../../assets/img/newDashboard/informacion.png';
import './NewRegisterpage.css';
import './../NewDashboard.css';
import icon_x from '../../../assets/img/newDashboard/Icon ion-close-circle-outline.png';
import icon_confirm_email from '../../../assets/img/newDashboard/Asset_icono_confirm_email.png';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../../../components/mobileComponents/Disclaimer';

const Register: FunctionComponent<any> = props => {
  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, changerepPass] = useState('');
  const [signining, setSignining] = useState(false);
  const [error, setError] = useState('');
  const [show, setShow] = useState(false);
  const [stillLoaded, SetStillLoaded] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const handleShowPassword = () => { setShowPassword(!showPassword) };
  const handleShowPassword2 = () => { setShowPassword2(!showPassword2) };
  const [downloading, setDownloading] = useState(false);

  const cookies = new Cookies();
  const history = useHistory();

  useEffect(() => {
    document.getElementById('entrada-email').focus();
    const savedRememberMe = cookies.get('rememberMe') === 'true';
    setRememberMe(savedRememberMe);

    if (savedRememberMe && cookies.get('myObj')) {
      const storedObj = cookies.get('myObj');
      setMail(storedObj.mail);
      setPassword(storedObj.password);
    } else {
      cookies.remove('myObj');
    }
  }, []);

  const postSendEMail = async (Newemail, Newpassword) => {
    try {
      const result = (await ObexRequestHandler.post('/signup_avapid', {
        email: Newemail,
        password: Newpassword
      })).data;

      if (result !== undefined){

        setShowModal(true);
      
      } else {
        setError('That email address is already registered')
        setTimeout(function () {
          setError('');
        }.bind(this), 3000)
      }
      //setShowModal(true); // Mostrar modal después de enviar la solicitud correctamente
    } catch (error) {
      console.error('ERROR POST', error);
    }
  };

  const postResendEMail = async (Newemail,) => {
    try {
      setDownloading(true);
      const result = (await ObexRequestHandler.post('/resend_validation_AvapID', {
        email: Newemail,

      })).data;
      console.log('Correo reenviado correctamente');
      setDownloading(false);
    } catch (error) {
      console.error('ERROR POST', error);
      setDownloading(false);
    }
    setDownloading(false);
  };

  const handleContinueClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSignining(true);

    var emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    var dd = mail.toString().match(emailFormat)

    var passFormat = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])([^\s]){8,200}$/;

    var de = password.toString().match(passFormat)

    console.log(de);
    console.log(dd);

    if (mail && password && repeatPassword && (password === repeatPassword) && dd && de) {
      await postSendEMail(mail, password);
    } else {
      if (dd) {
        if (de) {
          if (password !== repeatPassword) {
            setError('Your passwords do not match, please enter them again');
          }else{
            setError('Please fill in both email password and repeat password fields');
          }
        } else {
          setError('Your password does not meet the specifications');
        }
      } else if (password !== repeatPassword) {
        setError('Your passwords do not match, please enter them again');
      } else if (de === null) {
        setError('Your password does not meet the specifications');
      } else {
        setError('Please enter a correct email address');
      }
      setTimeout(function () {
        setError('');
      }.bind(this), 3000)
    }

    setSignining(false);
  };

  const handleSubmitSignup = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const path = 'https://www.101obex.com/pricing';
    window.location.href = path;
  };

  const changeMail = (e) => {
    const newMail = e.target.value;
    setMail(newMail);
  };

  const changePass = (e) => {
    const newPass = e.target.value;
    setPassword(newPass);
  };

  const ChangeRePass = (e) => {
    const newPass = e.target.value;
    changerepPass(newPass);
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setRememberMe(isChecked);
    cookies.set('rememberMe', isChecked, { path: '/' }); // Guardar en cookies
  };
  function routeHome() {
    const path = '/login';
    window.location.href = path;
  }

  const showModalMsg = () => {
    setShowModal(true);
  };

  const redirectToLogin = () => {
    setShowModal(false);
    history.push("/login");

  }

  return (
    <>
      <BrowserView>
    <div className="text-center d-flex flex-column containerLogin">
      {/*}
      <Navbar className='navbar' style={{position:'fixed', width:'100%'}}>
        <Container className="">
          <Navbar.Brand href="#home" style={{}}>
            <Image src={logo_framework} style={{ marginBottom: "5px" }} width="227px" alt="Logo" />
          </Navbar.Brand>
          <Nav style={{ width: "80%" }}>
          <Nav.Link href="#section1" style={{marginRight:"10px", marginLeft:"10px"}}>Products</Nav.Link>
            <Nav.Link href="#section2" style={{marginRight:"10px", marginLeft:"10px"}}>Partners</Nav.Link>
            <Nav.Link href="#section3" style={{marginRight:"10px", marginLeft:"10px"}}>Resource</Nav.Link>
            <Nav.Link href="#section4" style={{marginRight:"10px", marginLeft:"10px"}}>Company</Nav.Link>
            <Nav.Link href="#section4" style={{marginRight:"10px", marginLeft:"10px"}}>Contact Sales</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
*/}
      {stillLoaded ?
        <Container className="vh-100 d-flex flex-column vw-100">
          <Row className="h-100">
            <Col md="1" className="mx-auto d-flex align-items-center">
              <Spinner animation="border" />
            </Col>
          </Row>
        </Container>
        :
        <>
          <div className="justify-content-center pt-5 rowLogin" style={{ marginBottom: '7rem' }}>
            <div className="login-form px-5 pt-4">
            <Row className="justify-content-center" style={{marginTop:'7rem', marginBottom:'20px'}}>
              <Col >
                {/* <Image onClick={routeHome} src={Logo} fluid /> */}
                <Image onClick={routeHome} src={Logo} fluid
                  width="300"
                  style={{ marginBottom: '60px' }}
                />
                                   <h3 className='text-boldLogin'>Create your AVAP ID</h3>
                                   <h6 className='text-Login'>One AVAP ID is all you need to access all AVAP Framework products and services.</h6>
              </Col>
            </Row>

              <Form className="obex-form theForm">
                <Form.Group controlId="formBasicEmail">
                  <Form.Control id='entrada-email' required type="email" placeholder="Email" className="borderRadiusLogin" value={mail} onChange={changeMail} disabled={signining} tabIndex={1}/>
                  <div style={{ textAlign: "left", marginLeft: "0" }}>
                    <p className='fontText1' style={{ marginTop: "5px", marginLeft: "10px" }}>This will be your AVAP ID</p>
                  </div>
                </Form.Group>

                <Form.Group controlId="formBasicPassword" >
                  <div className='d-flex'>
                    {/* <Form.Control required type="password" placeholder="Password" className="borderRadiusLeftLogin" value={password} onChange={changePass} /> */}
                    {/* <div className="borderRadiusRightLogin btnNew btn-block info-icon-container d-flex justify-content-center align-items-center">
                      {!signining &&
                        <div className='d-flex justify-content-center align-items-center'>
                          <img
                            src={info}
                            alt="Info"
                            width="15px"
                            height="15px"
                            className="info-icon"
                          />
                          <div className="tooltip-text">
                            <ul>
                              <li>Your password must have:</li>
                              <li>8 or more characters</li>
                              <li>Upper & lowercase letters</li>
                              <li>At least one number</li>
                            </ul>
                          </div>
                        </div>
                      }

                      {signining && (
                        <div className='d-flex justify-content-center align-items-center'>
                          <Spinner animation="border" size="sm" style={{ color: "gray" }} />
                        </div>
                      )}
                    </div> */}
                    <InputGroup className="">
                      <Form.Control required type={showPassword ? 'text' : 'password'} placeholder="Password" className="borderRadiusLeftLogin" value={password} onChange={changePass} disabled={signining} tabIndex={2}/>
                      <InputGroup.Append>
                        <Button className='btnNew' onClick={handleShowPassword} style={{ background: "white", borderLeft: "0px", borderRight: "0px", borderTop: "1px #ced4da solid", borderBottom: "1px #ced4da solid", marginRight:"0px", width:'40px' }}>
                          {showPassword ?
                            <FontAwesomeIcon icon={faEyeSlash} style={{ color: "#AFAFAF" }} /> :
                            <FontAwesomeIcon icon={faEye} style={{ color: "#AFAFAF" }} />}
                        </Button>
                      </InputGroup.Append>
                      <InputGroup.Append>

                        <div style={{width:'40px'}} className="borderRadiusRightLogin btnNew btn-block info-icon-container d-flex justify-content-center align-items-center">
                        
                            <div className='d-flex justify-content-center align-items-center'>
                              <img
                                src={info}
                                alt="Info"
                                width="15px"
                                height="15px"
                                className="info-icon"
                              />
                              <div className="tooltip-text" style={{zIndex:99999999}}>
                              Your password must have:
                                <ul style={{marginTop:'10px'}}>
                                  
                                  <li>8 or more characters</li>
                                  <li>Upper & lowercase letters</li>
                                  <li>At least one number</li>
                                </ul>
                              </div>
                            </div>

                        </div>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <InputGroup className="">
                    <Form.Control required type={showPassword2 ? 'text' : 'password'} placeholder="Confirm Password" className="borderRadiusLogin" value={repeatPassword} onChange={ChangeRePass} disabled={signining} tabIndex={3}/>
                    <InputGroup.Append>
                      <Button className='btnNew' onClick={handleShowPassword2} style={{ background: "white", borderLeft: "0px", borderRight: "1px #ced4da solid", borderTop: "1px #ced4da solid", borderBottom: "1px #ced4da solid", width:'40px', borderTopRightRadius:'8px', borderBottomRightRadius:'8px' }}>
                        {showPassword2 ?
                          <FontAwesomeIcon icon={faEyeSlash} style={{ color: "#AFAFAF" }} /> :
                          <FontAwesomeIcon icon={faEye} style={{ color: "#AFAFAF" }} />}
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
                <button type="button" className=" custom-button textBold" style={{ display: "flex", width: "40%", marginBottom: signining ? '4px':'0px' }} onClick={handleContinueClick} disabled={signining} >
                  Continue
                  {signining && (
                    <div className='d-flex justify-content-center align-items-center mt-1 ml-1'>
                      <Spinner animation="border" size="sm" style={{ color: "gray" }} />
                    </div>
                  )}
                </button>


                <Row style={{display:'flex', justifyContent:'center'}}>
                    <Col style={{justifyContent:'center', textAlign:'center'}}>
                  {error.length > 0 && (
                    <Alert style={{position:'absolute', zIndex:99999999, width:'93%', marginTop:'15px'}} variant='danger'>
                      {error}
                    </Alert>)}
                  </Col>
                  </Row>


                {/*}
                {error.length > 0 && (
                  <Alert style={{position:'absolute', zIndex:99999999, width:'93%'}} variant='danger mt-3'>
                    {error}
                  </Alert>
                )}

                */}
              </Form>
            </div>
          </div>


          <Row className="mt-auto">
            <Col className="px-0">
              {/* <Footer /> */}
            </Col>
          </Row>
        </>
      }
      <div style={{ marginTop:'44px', zIndex:99 }}>
        <FooterMenu green={true}/>
      </div>

      <Modal dialogClassName="addon_layer addons-dialog obex-dialog" show={showModal} onHide={() => redirectToLogin()} id="modalImg" centered>
      <Modal.Header closeButton className="border-0 pb-0"> 
        <Modal.Title className='textBold colorBlack justify-content-center w-100 d-flex' style={{ fontSize: "1.9rem" }}>
          Confirm your email
        </Modal.Title >
        <button 
          style={{ border:'white', backgroundColor:'white' }}
          type="button" 
          className="btn-close-custom" 
          onClick={() =>  redirectToLogin()}
          
        >
        {/*}  <img src={iconosalida} alt="close" />*/}
        </button>
      </Modal.Header>
      <Modal.Body className=''>
        <div className='d-flex justify-content-center align-items-center'>
          <div className='d-flex flex-column align-items-center w-70'>
            <div className='mb-3 mt-2'>
             <img src={icon_confirm_email} alt="Confirm Email" />
            </div>
            <div className='colorBlack text-center fontVerify' style={{ marginBottom: "20px", lineHeight: "1.2" }}>
              Verify your email address. We have sent an email for you to confirm your account and start using AVAP ID
            </div>
            <div className='colorBlack'>
              Have you not received the confirmation email?
            </div>
            <div className='d-flex mt-3'>
              <div className=''>
                <button disabled={downloading} className="btn custom-button textBold" onClick={() => postResendEMail(mail)}>
                  Resend mail 
                  {downloading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px', marginBottom:'3px' }}/>}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    </div>
    </BrowserView>
      <MobileView>
        <div id='entrada-email'>
          <Disclaimer />
        </div>
      </MobileView>
    </>
  );
}

export default Register;