import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Container, Image, Form, Button, Spinner, Alert, Navbar, Nav } from 'react-bootstrap';
import PasswordRecovery from '../Modals/passwordRecovery';
import Logo from '../../assets/img/newAvapCloud/logo_Cloud@2x.png';
import Logo2 from '../../assets/img/ilustracion-serpentina.png'
import LogoCubos from '../../assets/img/cabecerainvite.png'
import VerificationNotice from './VerificationNotice';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import Footer from '../functionalComponents/DashboardLayout/FooterMenuAccess';
import './style.css';
import NewPassword from '../Modals/setNewPassword';
import logo_framework from "../../assets/img/avap_logo.png"
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/DisclaimerCloud';

type AuthProps = {
    Token?: number
  }

const AcceptInvitation:FunctionComponent<AuthProps> = (props: AuthProps) => {

    const { 
      Token
    } = props;
  
  const [codeResult, setCodeResult] = useState('');
  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');
  const [signining, setSignining] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showRecovery, setShowRecovery] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [stillLoaded, SetStillLoaded] = useState(false);
  const [mostrado, setMostrado] = useState(false);

  const handleCloseRecovery = () => setShowRecovery(false);
  const handleCloseNewPassword = () => setShowNewPassword(false);
  const handleShowRecovery = (e) => {
    e.preventDefault(); 
    setShowRecovery(true);
  };

  const handleShowNewPassword = (e) => {
    e.preventDefault(); 
    setShowNewPassword(true);
  };

  const history = useHistory();

  function routeDashboard() {
    history.push({
      pathname: '/admin'
    })
  }

  function routeHome() {
    const path = '/login';
    window.location.href = path;
  }

  function routePlans() {
    const path = '/Plans';
    window.location.href = path;
  }

  function routePrivate() {
    history.push({
      pathname: '/private'
    })
    
  }

  const redirectPage =  (signindata) =>{

      if (signindata['id_perfil']==6) routeDashboard();
      else if (signindata['id_perfil']==1) routePrivate();
      else routePlans();
    
  };

  const handleSubmitSignip = async (event) => {
    if (mostrado){
    {
    console.log(event);
    const encoded_password = encodeURIComponent(password);
    console.log(encoded_password);
    setSignining(true);
    const result = await ObexRequestHandler.get(`/accept_invitation?email=${mail}&password=${encoded_password}&token=${Token}`);
    console.log(result);
    if (result.success){
      console.log(result);
        if (result.data.code){
            const gg = document.getElementById('registro_entrada');
            const hh = document.getElementById('registro_entrada_success');
            gg.hidden = true;
            hh.hidden = false;
            setCodeResult(result.data.code);
        } else {
        setError(result.data.message);
        }
    } else {
        setError(result.message);
    }
    setSignining(false);
  }
} else {
  setMostrado(true);
}
  };


  const handleSubmitSignup = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    routePlans();    
  };


  const changeMail = (e) => {
    const newMail = e.target.value;
    setMail(newMail);
  }

  const changePass = (e) => {
    const newPass = e.target.value;
    setPassword(newPass);
  }




  return(

    <>
      <BrowserView>
    
    

<div className="text-center d-flex flex-column containerLogin ">


    {/*<Container fluid className="text-center d-flex flex-column min-vh-100">*/}


<Navbar className='navbar' style={{position:'fixed', width:'100%'}}>
            <Container className="">
            <Navbar.Brand href="#home" style={{}}>
                <Image src={logo_framework} style={{ marginBottom: "5px" }} width="227px" alt="Logo" />
            </Navbar.Brand>
            <Nav style={{ width: "80%" }}>
            <Nav.Link href="#section1" style={{marginRight:"10px", marginLeft:"10px"}}>Products</Nav.Link>
            <Nav.Link href="#section2" style={{marginRight:"10px", marginLeft:"10px"}}>Partners</Nav.Link>
            <Nav.Link href="#section3" style={{marginRight:"10px", marginLeft:"10px"}}>Resource</Nav.Link>
            <Nav.Link href="#section4" style={{marginRight:"10px", marginLeft:"10px"}}>Company</Nav.Link>
            <Nav.Link href="#section4" style={{marginRight:"10px", marginLeft:"10px"}}>Contact Sales</Nav.Link>
            </Nav>
            </Container>
        </Navbar>

        
    {stillLoaded ?
      <Row className="h-100" >
          <Col md="1" className="mx-auto d-flex align-items-center" style={{ marginTop: '25em' }}>
            <Spinner animation="border"/>
          </Col>
        </Row>:
      <><Row className="justify-content-center pt-5 mt-5" style={{display: 'grid'}}>

        <div id = 'registro_entrada'>
          <Row className="justify-content-center " style={{marginTop:"5.5rem", maxWidth:'440px'}}>
              <Col >
                {/* <Image onClick={routeHome} src={Logo} fluid /> */}
                <Image onClick={routeHome} src={Logo} fluid
                  width="300px"
                  style={{ marginBottom: '60px' }}
                />
                 <h5 className='text-boldLogin'>You have about to accept an invitation to form part of an organization</h5>
              </Col>
            </Row>
            <Row>
            <Col style={{ marginBottom: '30px' }}>
            </Col>
            </Row>
            
{/*}
            <Row>
              <Col style={{ marginBottom: '30px' }}>
            <div style={{fontWeight: 500, fontSize: '18px', color: 'black' }}>You have about to accept an invitation to</div>
            </Col>
            </Row>
            <Row>
              <Col style={{ marginBottom: '30px' }}>
            <div style={{ fontWeight: 500, fontSize: '18px', color: '#2685d1', marginTop:'-30px' }}>from part of an organization</div>
            </Col>
            </Row>

*/}
            <Row>
              <Col style={{ marginBottom: '30px' }}>
            <div style={{ fontWeight: 500, fontSize: '13px', marginTop:'-10px' }}>Login and start to collaborate</div>
            </Col>
            </Row>


            <Row>
              <Col>
              
                <Form className="obex-form" style={{maxWidth:'80%', margin: 'auto'}}>

                  { mostrado &&
                  <><Form.Group controlId="formBasicEmail">
                      <Form.Control className='borderRadiusLogin' style={{borderRadius:'0.5rem'}} required type="email" placeholder="Mail" onChange={changeMail} />
                    </Form.Group><Form.Group controlId="formBasicPassword">
                        <Form.Control className='borderRadiusLeftLogin' style={{borderRadius:'0.5rem'}} required type="password" placeholder="Password" onChange={changePass} />
                      </Form.Group></>

}
                  <Button style={{}} className="my-2 custom-button-blue btn-block textBold" onClick={handleSubmitSignip} disabled={signining}>
                    {signining ? 'Accessing' : 'Login'}
                    {signining && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                  </Button>
                  {error.length > 0 && (
                    <Alert variant='danger'>
                      {error}
                    </Alert>)}

                    <Row>
                      <Button className="my-2" style= {{ height:'34px', borderRadius:'0.5rem', backgroundColor: 'transparent', borderColor: 'black', width:'100%', marginLeft:'15px', marginRight:'15px'}}>
                        <a className="btn-block textBold" style={{ color: 'black', borderRadius:'0.5rem' }} onClick={handleShowNewPassword}>First time?</a>
                      </Button>
                    </Row>


                </Form>
              </Col>
           
            </Row>
            
            <Row>
              <Col className="my-2">
                <a className="link_forgetpass_blue" onClick={handleShowRecovery}>Are you having problems with signing in?</a>
              </Col>
            </Row>
            </div>
            {/*
    <Row>
      <Col className="my-3">
        <div className="border-top divider-login"></div>
      </Col>
    </Row>
    <Row>
      <Col className="my-2">
      <Form>
          <Button type="submit" variant="light" className="btn-block singin" onClick={handleSubmitSignup}>
            Create account
          </Button>
        </Form>
      </Col>
    </Row>
    */}
   {/*}       </div>*/}


          <div id = 'registro_entrada_success' className="mx-auto border rounded px-5 pt-3 pb-4 " hidden={true}>
            <Row className="justify-content-center my-4">
              <Col >
                <Image onClick={routeHome} src={Logo2} fluid                             
                            width="180"
                            style={{ marginBottom: '60px' }}
                            />
                <h1><b>INVITATION ACCEPTED</b></h1>
                <Row style={{ justifyContent:'center' }}>
                    Access to your organization resources granted.
                </Row>

                <Row style={{ justifyContent:'center' }}>
                  You can close this windows.
                </Row>
              </Col>
            </Row>

          </div>
          <PasswordRecovery show={showRecovery} handleClose={handleCloseRecovery}/>
          <NewPassword show={showNewPassword} handleClose={handleCloseNewPassword} Token={Token} />
        </Row><VerificationNotice
            mail={mail}
            show={show}
            handleClose={handleClose} />


<Row style={{ marginBottom: '80px' }}>
                  <Col style={{ textAlign: 'center' }}>
                    {/* <span>Need to create a new organization?</span> */}
                    {/* IM REMOVE THIS 
            <a onClick={handleSubmitSignup} style={{ color:'#7abcb0' }}> Sign up </a> */}
                    {/* <span>here.</span> */}
                  </Col>
                </Row>

                

            <Row >
            <Col >
              <Footer />
            </Col>
          </Row></> 
}
    </div>
    </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  );
}
export default AcceptInvitation;