import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import BaseLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import { Row, Col, Table, Button, Spinner, Form, Accordion, Card, Image } from 'react-bootstrap';
import TransactionsMediaChart from './GraphComponents/TransactionsMediaChart';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit, faPencilAlt, faPlusCircle, faChevronUp, faChevronDown, faTimes, faBan, faCross, faCheck, faBars,
  faRedoAlt, faSitemap, faCog, faChevronRight, faCodeBranch, faUpload, faLink, faInfo, faAdjust,
} from '@fortawesome/free-solid-svg-icons';
import UpdateRateLimitModal from '../../components/Modals/updateRateLimitModal';
import AddTxSelModal from '../Modals/addTxModalSelect';
import { firstDayInThisMonth, lastDayInThisMonth } from '../../services/TimeService';
import dropdown from '../../assets/img/newDashboard/icono-flecha-expandir.png';

import Logo from '../../assets/img/ilustracion_noInfo2.png'
import SessionService from '../../services/SessionsService';
import AddAPIProductModal from '../Modals/addAPIProductModal';
import AddLinkModal from '../Modals/addLinkModal';

//ui
import commit from '../../assets/img/ui_icons/code-commit-solid.svg';
import check from '../../assets/img/ui_icons/circle-check-solid.svg';
import xmark from '../../assets/img/ui_icons/circle-xmark-solid.svg';


import { Tree } from '@geist-ui/react'

type PublisherProps = {
};


const PublisherContent: FunctionComponent<PublisherProps> = props => {
  const menuActive = 'transactions';
  let puntose = '';
  let contadore = 0;
  const [loadingTable, setLoadingTable] = useState(false);
  const [editedProject, setEditedProject] = useState({ name: '', id: '', currentLimit: 0, currentConsumed: 0 });
  const [projects, setProjects] = useState([]);
  const [Api_products, setApi_products] = useState([]);
  const [projectsMovements, setProjectMovements] = useState({});
  const [projectTx, setProjectTx] = useState([]);
  const [globalTx, setGlobalTx] = useState({ limit: 0, current: 0, rest: 0 });
  const [editing, setEditing] = useState(false);
  const [addingTx, setAddingTx] = useState(false);
  const [maxTX, setMaxTX] = useState(3100);
  const [APIProducts, setAPIProducts] = useState([]);
  const [APIPublished, setAPIPublished] = useState([]);
  const [actualTX, setActualTX] = useState(0);
  const [publishing, setPublishing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [sublinks, setSublinks] = useState([

  ]);
  const [allLoaded, setAllLoaded] = useState(true);

  const [currentProject, setProject] = useState('');
  const [from, setFrom] = useState(firstDayInThisMonth());
  const [to, setTo] = useState(lastDayInThisMonth());
  const [activeKey, setActiveKey] = useState('0');
  const [cardsLoading, setCardsLoading] = useState(true);
  const [selectedProduc, setSelectedProduct] = useState('');
  const [selectedAPIProduct, setSelectedAPIProduc] = useState();


  const handleAddingTx = () => setAddingTx(true);

  const handleModal = () => {
    //getProjects('');

    setShowModal(!showModal);
    getInfo();
  };

  const handleModal2 = () => {
    //getProjects('');

    setShowModal2(!showModal2);
    getInfo();
  };

  const handleProject = async (proj) => {
    setProject(proj)

    const publishedApis = (await ObexRequestHandler.get(`/publishing/get_publish_products_by_session?obex_project_id=${proj}`, {}, true)).data || [];

    let listado = [];
    let optProd = [];
    let conta = 0;
    let products = publishedApis.Products;

    setAPIPublished(publishedApis.TotalAPIS);
    let unpublished = [];
    let apis = publishedApis.TotalAPIS;
    apis.forEach(element => {
      if (element.product === 'uncatalogued') unpublished.push(element);

      {/*}
        if (element.product === '') {
          console.log(element);
          products.forEach(elemento => {
            console.log(elemento)
          });

        }
*/}
    });
    listado.push({ id: 0, catalogo: unpublished, name: 'Unpublished' })
    products.forEach(element => {
      if (conta == 0) setSelectedProduct(element.name);
      optProd.push({ id: element.name, description: element.name })

      let lunpublished = [];
      apis.forEach(elemento => {
        if (elemento.product == '') {
          products.forEach(elementos => {
            if (elementos.id === elemento.api_product_id) { elemento.product = elementos.name; elemento.link = true }
          });

        }
        if (elemento.product === element.name) lunpublished.push(elemento);

      });


      listado.push({ id: element.id, catalogo: lunpublished, name: element.name })
      conta++;
    });
    setAPIProducts(listado);
    setApi_products(optProd);
    console.log(listado);

  }


  const initpublishAPI = (var1, var2, var3, var4) => {

    setPublishing(true);

  }

  const publishAPI = async (var1, var2, var3, var4) => {
    setPublishing(false);
    console.log(var1, var2, var3, var4);
    console.log(publishing);

    let result = await ObexRequestHandler.post(`/publishing/publish_virtual_api?api=${var3.name}&commit_id=${var1.commit_id}&ambient=${var4.ambient}&obex_project_id=${currentProject}&product=${selectedProduc}`)
    console.log(result);
    getInfo();
  }

  const getInfo = async () => {
    try {
      setLoadingTable(true);

      const projectsInfo = (await ObexRequestHandler.get(`/projects?organization_id=${SessionService.ORGANIZATION}`, {}, true)).data || [];

      setProjects(projectsInfo);

      const firstProjectName = projectsInfo[0].id;
      handleProject(firstProjectName);
      setAllLoaded(false);

    } catch (error) {

      setAllLoaded(false);
      console.error('ERROR GETTING INFO ', error)

    }
    setLoadingTable(false);
    setCardsLoading(false);
  }

  const handleEdit = () => {
    setEditing(!editing);
  }

  const editPencil = (project) => {

    const editedProject = projectTx.find(p => p.id === project);
    setEditedProject({
      name: editedProject.name,
      id: editedProject.id,
      currentLimit: editedProject.total,
      currentConsumed: editedProject.current
    });

    handleEdit();
  }


  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };


  const handleSelectProject = (e) => {
    setProject(e.target.value); // Actualiza el estado del proyecto seleccionado
  };

  const loadingSpinner = <Row className="my-3"><Col md={1} className="mx-auto"><Spinner animation="border" /></Col></Row>;
  const noProjectsText =       <div>
  <><Row className="justify-content-center my-4">
      <Col xs="6" style={{display:'flex', justifyContent:'center'}}>
        <Image style={{width:'50%', marginTop:'60px', marginRight:'0'}} src={Logo} fluid />
      </Col>
      </Row>
      <Col style={{textAlign:'center', marginBottom:'60px', marginRight:'0'}}>
      <Row style={{marginRight:'0'}}><Col><h5>You don’t have data to show</h5></Col></Row>
      <Row style={{marginRight:'0'}}><Col>When you have data to show, you’ll find here.</Col></Row>
     {/*} <Row style={{marginRight:'293px'}}><Col>want to track, you'll find here. </Col></Row>*/}
      </Col>
    </>
    </div>;

  const whenClosing = () => {
    setAddingTx(false);
  }

  useEffect(() => {
    getInfo();
    setPublishing(false);
  }, []);

  const optionProjects = projects.map(value => <option value={value.id}>{value.description}</option>);

  const APIProducstOptions = Api_products.map(value => <option value={value.id}>{value.description}</option>);

  return (
    <BaseLayout sider={DashRouters} active={'publisher'} sublinks={sublinks}>

      <div style={{ marginLeft: '-17px', marginTop: '5px', marginRight: '20px' }}>
        <Row >
          <Col style={{ width: '30%', maxWidth: '30%', marginBottom: '1rem' }}>
            <h2 className='textBold'>APIs Published</h2>
          </Col>
          <Col style={{ width: '70%', maxWidth: '70%', textAlign: 'end' }}>
            <button style={{ marginLeft: '10px', width: '230px' }} disabled={allLoaded} className="custom-button-blue textBold" onClick={handleModal}>Add API Product</button>
            <button style={{ marginLeft: '10px', width: '230px' }} disabled={allLoaded} className="custom-button-blue textBold" onClick={handleModal2}>Add Link</button>
            <button style={{ marginLeft: '10px' }} disabled={allLoaded} className="custom-button-blue textBold" onClick={() => { getInfo() }}><FontAwesomeIcon icon={faRedoAlt} className="button-sort" size="1x" /></button>
          </Col>
        </Row>

        <div className='fontText3 colorBlackPI' style={{ marginBottom: '2rem', marginLeft: '0px' }}>
          Find and manage your published APIs and add new products to your portfolio.
        </div>

        {loadingTable && loadingSpinner}
        {(!loadingTable && projects.length <= 0) && noProjectsText}

        {(!loadingTable && projects.length > 0) &&
          <Row style={{ marginTop: '2rem' }}>
            <Col>
              <Form.Control as="select" onChange={(e) => handleProject(e.target.value)} custom defaultValue={currentProject}>
                {optionProjects}
              </Form.Control>
            </Col>
          </Row>
        }
        <div style={{
          marginBottom: '3rem'
        }}>

          <div style={{ marginTop: '40px' }}>
            <Tree>
              {
                APIProducts.map(c => (
                  <Tree.Folder name={c.name} extra=''>
                    <div id={``} onClick={() => { console.log('') }} key={c.id} style={{
                      marginTop: '0px'
                    }}><div className='textBold' style={{
                      fontSize: '1.1rem'
                    }}>


                        {/*}    <FontAwesomeIcon icon={faSitemap} className="button-sort mr-1" size="1x"/>{c.name}*/}</div>


                      {c.catalogo.map(cd => (
                        // @ts-ignore
                        <Tree.Folder style={{ marginLeft: '30px' }} name={
                          cd.link ? (
                            <>
                              <FontAwesomeIcon style={{ width: '15px' }} icon={faLink} className="button-sort mr-1" size="1x" />
                              {cd.name} {/* Muestra el nombre después del icono */}
                            </>
                          ) : (
                            <>
                              <FontAwesomeIcon style={{ width: '15px' }} icon={faCog} className="button-sort mr-1" size="1x" />
                              {cd.name} {/* Muestra el nombre después del icono */}
                            </>
                          )
                        }
                          extra={cd.endpoint}
                        >
                          <div key={cd.id} style={{ marginTop: '0px', marginBottom: '10px' }}> {/*<h3 className='' style={{ paddingLeft:'40px', fontSize:'1.1rem', color:'#595959' }}>{cd.link ? <FontAwesomeIcon icon={faLink} className="button-sort mr-1" size="1x"/>:<FontAwesomeIcon icon={faCog} className="button-sort mr-1" size="1x"/>}{cd.name}</h3>*/}
                            {cd.link &&
                              <>
                                {/* <div key={cd.endpoint} style={{ display: 'flex', marginTop: '10px', paddingLeft: '10px', fontSize: '0.87rem' }}>
                                  <div style={{ marginRight: '7px' }}>
                                    <FontAwesomeIcon style={{ width: '5px' }} icon={faInfo} className="button-sort mr-1" size="1x" />
                                  </div>
                                  {cd.endpoint}
                                </div> */}
                                <div key={cd.method} style={{ display: 'flex', marginTop: '15px', paddingLeft: '10px', fontSize: '0.87rem' }}>
                                  <div style={{ marginRight: '7px' }}>
                                    <FontAwesomeIcon icon={faLink} className="button-sort mr-1 treeIcon" size="1x" />
                                  </div>
                                  Method: {cd.method}
                                </div>
                                <div  style={{ display: 'flex', marginTop: '15px', paddingLeft: '10px', fontSize: '0.87rem' }}>
                                  <div style={{ marginRight: '7px' }}>
                                    <FontAwesomeIcon icon={faLink} className="button-sort mr-1 treeIcon" size="1x" />
                                  </div>
                                  Type: Rest
                                </div>
                                <div key={cd.target} style={{ display: 'flex', marginTop: '15px', paddingLeft: '10px', fontSize: '0.87rem' }}>
                                  <div style={{ marginRight: '7px' }}>
                                    <FontAwesomeIcon  icon={faLink} className="button-sort mr-1 treeIcon" size="1x" />
                                  </div>
                                  Target:{cd.target}
                                </div>

                              </>
                            }
                            {cd.ambients.map(ambiente => (
                              <div key={ambiente.name} style={{ marginTop: '5px', paddingLeft: '33px', fontSize: '0.87rem' }}>
                                <Tree.Folder name={ambiente.ambient} title='prueba' extra={ambiente.lastversion != 0 ? `Last published v${ambiente.lastversion}` : 'unpublished'}>
                                  {/*{ambiente.ambient}*/}

                                  {/*<span style={{marginLeft:'10px', color:'#bdbdbd', fontSize:'0.87rem'}}> {ambiente.lastversion != 0 ? `Last published v${ambiente.lastversion}`:'unpublished' }</span>*/}


                                  {


                                    (ambiente.versions.length > 1 ?

                                      ambiente.versions.slice(-2, -1).map(versiones => (

                                        (

                                          <div key={versiones.commit_id} style={{ marginTop: '5px', paddingLeft: '10px', fontSize: '0.87rem' }}>
                                            <div style={{ width: '100%', display: 'flex' }}>
                                              <div style={{ width: '50%', color: '#595959' }}>
                                                <Image className='treeIcon deg90' src={commit} />
                                                {versiones.commit_id}
                                              </div>
                                              <div>{versiones.message}</div>
                                              <div style={{ cursor: 'pointer', width: '20%', color: '#147bce' }}>{
                                                publishing == true ?
                                                  <div style={{ display: 'flex', marginTop: '-10px' }}>
                                                    {/*<Form.Control style={{ fontSize: '0.8rem', height: '20px', marginTop: '7px', paddingTop: '0px' }} as="select" onChange={(e) => { setSelectedProduct(e.target.value); console.log(e.target.value) }} custom defaultValue={currentProject}>*/}
                                                    { c.name !== 'Unpublished' ? '' : 
                                                    <Form.Control style={{ fontSize: '0.8rem', height: '20px', marginTop: '7px', paddingTop: '0px' }} as="select" onChange={(e) => { setSelectedProduct(e.target.value); console.log(e.target.value) }} custom defaultValue={currentProject}>
                                                    APIProducstOptions
                                                    </Form.Control>
                                                    }
                                                    {/*</Form.Control>*/}

                                                    <button onClick={(e) => { publishAPI(versiones, c, cd, ambiente); setPublishing(false); }} style={{ marginTop: '5px', marginLeft: '10px', padding: '2px', height: '23px', color: '#595959' }} className='custom-button-blue'><FontAwesomeIcon style={{ width: '10px' }} icon={faCheck} /></button>
                                                    <button onClick={(e) => { setPublishing(false); }} style={{ marginTop: '5px', marginLeft: '10px', padding: '2px', height: '23px', color: '#595959' }} className='custom-button-blue'><FontAwesomeIcon icon={faTimes} /></button>

                                                  </div> :
                                                  <span style={{ color: '#595959' }} onClick={() => { initpublishAPI(versiones, c, cd, ambiente) }}><FontAwesomeIcon icon={faUpload} className="button-sort mr-1" size="1x" /></span>

                                              }</div>
                                            </div>


                                          </div>

                                        )




                                      ))
                                      : ambiente.versions.slice(-1).map(versiones => (

                                        (

                                          <div key={versiones.commit_id} style={{ marginTop: '5px', paddingLeft: '10px', fontSize: '0.87rem' }}>


                                            <div style={{ width: '100%', display: 'flex' }}>
                                              <div style={{ width: '50%', color: '#595959' }}>
                                                <Image className='treeIcon deg90' src={commit} />
                                                {versiones.commit_id}
                                              </div>

                                              <div style={{ width: '30%', overflow: 'hidden' }}>{versiones.message}</div>
                                              <div style={{ cursor: 'pointer', width: '20%', color: '#147bce' }}>{
                                                publishing == true ?
                                                  <div style={{ fontSize: '0.8rem', display: 'flex', marginTop: '-10px' }}>
                                                    {/*<Form.Control style={{ fontSize: '0.8rem', height: '20px', marginTop: '7px', paddingTop: '0px' }} as="select" onChange={(e) => { setSelectedProduct(e.target.value); console.log(e.target.value) }} custom defaultValue={currentProject}>*/}
                                                    { c.name !== 'Unpublished' ? '' : 
                                                    <Form.Control style={{ fontSize: '0.8rem', height: '20px', marginTop: '7px', paddingTop: '0px' }} as="select" onChange={(e) => { setSelectedProduct(e.target.value); console.log(e.target.value) }} custom defaultValue={currentProject}>
                                                    APIProducstOptions
                                                    </Form.Control>
                                                    }
                                                    {/*</Form.Control>*/}

                                                    <button onClick={(e) => { publishAPI(versiones, c, cd, ambiente); setPublishing(false); }} style={{ marginTop: '5px', marginLeft: '10px', padding: '2px', height: '23px', color: '#595959' }} className='custom-button-blue'><FontAwesomeIcon style={{ width: '10px' }} icon={faCheck} /></button>
                                                    <button onClick={(e) => { setPublishing(false); }} style={{ marginTop: '5px', marginLeft: '10px', padding: '2px', height: '23px', color: '#595959' }} className='custom-button-blue'><FontAwesomeIcon icon={faTimes} /></button>

                                                  </div> :
                                                  <span style={{ color: '#595959' }} onClick={() => { initpublishAPI(versiones, c, cd, ambiente) }}><FontAwesomeIcon icon={faUpload} className="button-sort mr-1" size="1x" /></span>

                                              }</div>
                                            </div>

                                            {ambiente.versions.map((vers) => {
                                              {
                                                <Tree.Folder name={vers.commit_id}>
                                                  <Tree.File name="o"></Tree.File>
                                                </Tree.Folder>
                                              }
                                            })

                                            }


                                          </div>

                                        )


                                      ))

                                    )
                                  }

                                  {/* {ambiente.versions} */}

                                  {/* <pre>{JSON.stringify(ambiente.versions, null, 2)}</pre> */}


                                  {ambiente.versions
                                    .filter(versionData => versionData.version !== "unpublished")

                                    .map((versionData, index) => (
                                      // @ts-ignore
                                      <Tree.Folder key={index} extra={
                                        <>
                                         {versionData.tests && versionData.tests.some(test => test.result === '{succes:true}') ? (
                                        <span>ok</span>
                                          ) : (
                                            <span>ko</span>
                                          )}
                                        </>
                                      } 
                                          // @ts-ignore
                                      name={
                                        <>
                                          {/* Mostrar el ícono dependiendo del resultado del test */}
                                          {versionData.tests && versionData.tests.some(test => test.result === '{succes:true}') ? (
                                            <Image className='treeIcon mr-2 icon-success' src={check} />
                                          ) : (
                                            <Image className='treeIcon mr-2 icon-error' src={xmark} />
                                          )}

                                          {/* Mostrar el nombre de la versión */}
                                          {`v-${versionData.version}`}
                                        </>
                                      }>

                                        {/* Archivos dentro de la versión */}
                                        {versionData.tests && versionData.tests.length > 0 && (
                                          <div>
                                            {versionData.tests.map((test, testIndex) => (
                                              // @ts-ignore
                                              <Tree.Folder key={testIndex} extra={
                                                <>
                                                {versionData.tests && versionData.tests.some(test => test.result === '{succes:true}') ? (
                                               <span>ok</span>
                                                 ) : (
                                                   <span>ko</span>
                                                 )}
                                               </>
                                              }
                                                // @ts-ignore                                              
                                              name={
                                                <>
                                                  {/* Mostrar el ícono dependiendo del resultado del test */}
                                                  {versionData.tests && versionData.tests.some(test => test.result === '{succes:true}') ? (
                                                    <Image className='treeIcon mr-2 icon-success' src={check} />
                                                  ) : (
                                                    <Image className='treeIcon mr-2 icon-error' src={xmark} />
                                                  )}

                                                  {/* Mostrar el nombre de la versión */}
                                                  {test.name}
                                                </>

                                              }>

                                                <Tree.Folder name='Description'>
                                                  {test.name}
                                                </Tree.Folder>
                                                <Tree.Folder name='Result'>
                                                  {test.result}
                                                  {/* {test.result === '{succes:true}' ? (
                                                    <FontAwesomeIcon icon={faCheck} style={{ color: 'green', marginLeft: '10px' }} />
                                                  ) : (
                                                    <FontAwesomeIcon icon={faTimes} style={{ color: 'red', marginLeft: '10px' }} />
                                                  )} */}
                                                </Tree.Folder>
                                              </Tree.Folder>

                                            ))}
                                          </div>
                                        )}
                                      </Tree.Folder>
                                    ))}
                                </Tree.Folder>
                              </div>
                            )

                            )

                            }

                          </div>
                        </Tree.Folder>
                      )




                      )}

                    </div>
                  </Tree.Folder>
                )


                )


              }

            </Tree>


          </div>

        </div>
        {/*}
    <Tree>
      <Tree.Folder name="src">
        <Tree.File name="app.js" />
        <Tree.File name="index.js" />
        <Tree.Folder name="Components">
          <Tree.File name="Layout.js" />
        </Tree.Folder>
      </Tree.Folder>
      <Tree.File name="package.json" />
    </Tree>
*/}

        {showModal &&
          <AddAPIProductModal
            show={showModal}
            handleClose={handleModal}
            projectId={Number(currentProject)}
          />

        }

        {showModal2 &&
          <AddLinkModal
            show={showModal2}
            handleClose={handleModal2}
            projectId={Number(currentProject)}
            APIProducstOptions={APIProducstOptions}
            APIProductSelected={selectedProduc}
          />
        }
      </div>
    </BaseLayout>)
}

export default PublisherContent;