import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { Row, Col, Navbar, Container, Nav, Image, NavDropdown, Dropdown, Card, Button } from 'react-bootstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import './NewLandingFramework.css'
import FooterMenu from '../../components/functionalComponents/DashboardLayout/FooterMenu';
import Logo from '../../assets/img/../../assets/img/AVAP_ID_horizontal.png';
import icon_name from '../../assets/img/newDashboard/icon_name.png';
import icono_Infinito from '../../assets/img/newDashboard/icono-Infinito.png';
import logo_communities from '../../assets/img/newDashboard/logo_communities.png';
import logo_alexandria from '../../assets/img/newDashboard/logo-alexandria.png';
import logo_Connect from '../../assets/img/newDashboard/logo-Connect.png';
import Logo101Obex from '../../assets/img/newDashboard/icono_Framework.png';
import logo_framework from '../../assets/img/avap_logo.png'

import logo_framework_producto from '../../assets/img/AVAP_Framework_cabecera.svg'

import lenguaje from '../../assets/img/01_ilustracion_HightLevel@2x.png'
import virtualidad from '../../assets/img/01_ilustracion_virtualidad@2x.png'
import inteligencia from '../../assets/img/01_ilustracion_AILenguage@2x.png'

import logo_framework_titulo from '../../assets/img/AVAP_Framework_cabecera.svg'
import cabecera from '../../assets/img/cabecera.png'
import cabecera2 from '../../assets/img/2cabecera.png'
import FooterMenuAccess from '../../components/functionalComponents/DashboardLayout/FooterMenu';
import FooterMenuAccessMobile from '../../components/functionalComponents/DashboardLayout/FooterMenuMobile';
import MobileNabvar from '../../components/functionalComponents/baseLayout/mobileNavbar';

import botonAvapActivo from '../../assets/img/boton_avap_activo.png'
import botonCloudActivo from '../../assets/img/boton_cloud_activo.png'
import botonDsActivo from '../../assets/img/boton_ds_activo.png'
import botonCnpActivo from '../../assets/img/boton_cn_activo.png'
import botonGActivo from '../../assets/img/boton_g_activo.png'
import botonVspActivo from '../../assets/img/boton_vs_activo.png'

import botonAvap from '../../assets/img/boton_avap.png'
import botonCloud from '../../assets/img/boton_cloud.png'
import botonDs from '../../assets/img/boton_ds.png'
import botonCnp from '../../assets/img/boton_cn.png'
import botonG from '../../assets/img/boton_g.png'
import botonVs from '../../assets/img/boton_vs.png'

//responsive buttons

import lenguajeMb from '../../assets/img/01_ilustracion_HightLevel.svg'
import virtualidadMb from '../../assets/img/01_ilustracion_virtualidad.svg'
import inteligenciaMb from '../../assets/img/01_ilustracion_AILenguage.svg'
import productividadMb from '../../assets/img/2_ilustracion_productivity.svg'
import rentabilidadMb from '../../assets/img/2_ilustracion_higherProfitability.svg'
import focoproyectosMb from '../../assets/img/2_ilustracion_maximize.svg'
import tiemposlanzamientoMb from '../../assets/img/2-ilustracion_fasterTime.svg'

import res_botonAvapActivo from '../../assets/img/res_icon_avap_active.png'
import res_botonCloudActivo from '../../assets/img/res_icon_cloud_active.png'
import res_botonDsActivo from '../../assets/img/res_icon_ds_active.png'
import res_botonCnpActivo from '../../assets/img/res_icon_cn_active.png'
import res_botonGActivo from '../../assets/img/res_icon_g_active.png'
import res_botonVspActivo from '../../assets/img/res_icon_vs_active.png'

import res_botonAvap from '../../assets/img/res_icon_avap.png'
import res_botonCloud from '../../assets/img/res_icon_cloud.png'
import res_botonDs from '../../assets/img/res_icon_ds.png'
import res_botonCnp from '../../assets/img/res_icon_cn.png'
import res_botonG from '../../assets/img/res_icon_g.png'
import res_botonVs from '../../assets/img/res_icon_vs.png'

import AVAPLanguage from '../../assets/img/AVAPLenguaje.png';
import AVAPVirtualServer from '../../assets/img/AVAPVS.png';
import AVAPGateway from '../../assets/img/AVAPGateway.png';
import AVAPDS from '../../assets/img/AVAPDS.png';
import AVAPCloudLogo from '../../assets/img/AVAPCloud.png';
import AVAPCollaborative from '../../assets/img/AVAPCollaborativeN.png'

import Virtualizacion from '../../assets/img/foto_virtualizacion.png'

import productividad from '../../assets/img/2_ilustracion_productivity@2x.png'
import rentabilidad from '../../assets/img/2_ilustracion_higherProfitability@2x.png'
import focoproyectos from '../../assets/img/2_ilustracion_maximize@2x.png'
import tiemposlanzamiento from '../../assets/img/2-ilustracion_fasterTime@2x.png'

import collaborative2 from '../../assets/img/collaborative2.png'

import icon_collaborative from '../../assets/img/iconCollaborative.png'
import connect from '../../assets/img/connect.png'
import avapconect from '../../assets/img/AVAPConnect.png'
import ReactGA from 'react-ga4';
import config from '../../configs/appConfig.json'
import favicon from '../../assets/img/Favicon_AVAP_Framework.png'

import MobileLayout from '../../components/functionalComponents/Mobile_Layout/MobileLayout';
import BrowserNavbar from '../../components/functionalComponents/baseLayout/browserNavbar';

type DashProps = {
    sider: any
};



const NewLanding = () => {
    let { path, url } = useRouteMatch();

    const history = useHistory();

    const [avapBut, setavapBut] = useState(true);
    const [vsBut, setvsBut] = useState(false);
    const [gBut, setgBut] = useState(false);
    const [dsBut, setdsBut] = useState(false);
    const [cloudBut, setcloudBut] = useState(false);
    const [CnBut, setcnBut] = useState(false);

    const [menu1, setmenu1] = useState(false);
    const [menu2, setmenu2] = useState(false);
    const [menu3, setmenu3] = useState(false);
    const [menu4, setmenu4] = useState(false);
    const [menu5, setmenu5] = useState(false);


    const cleanAll = () => {
        setavapBut(false);
        setvsBut(false);
        setgBut(false);
        setdsBut(false);
        setcloudBut(false);
        setcnBut(false);
    }

    const cleanMenuAll = () => {
        setmenu1(false);
        setmenu2(false);
        setmenu3(false);
        setmenu4(false);
        setmenu5(false);
    }


    useEffect(() => {
        // favicon
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = favicon; // Usa la ruta importada directamente
        document.head.appendChild(link);

        // favicon
        return () => {
            document.head.removeChild(link);
        };



    window.scrollTo(0, 0);
    //ReactGA.initialize('G-MQZQJN7TQL');
    if (config.url != 'https://avs-primary-pre.101obex.mooo.com') {
        ReactGA.initialize('G-MQZQJN7TQL');
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Framework' });
    }

}, []);


const handleSignInClick = () => {
    // Aquí puedes realizar cualquier acción antes de redireccionar, si es necesario

    // Redirecciona a otra vista (por ejemplo, '/dashboard')
    history.push('/login');
};

const navbarRef = useRef(null);

const handleClick = () => {
    // Llama a la función cleanMenuAll del componente BrowserNavbar
    if (navbarRef.current) {
        navbarRef.current.cleanMenuAll(); // O también navbarRef.current.cleanAll();
    }
};





return (

    <>
        <BrowserView>


            <div id='frameworkMain' className='d-flex flex-column' style={{ backgroundColor: '#fafafa' }}>
                <BrowserNavbar ref={navbarRef} />
                <div className='mainFont' onClick={() => { handleClick() }}>
                    <header id='mainHeader' className="w-100 mtLead vh-100 headbg" style={{ marginTop: '40px', backgroundImage: `url(${cabecera})`, }} >
                        <div className="container">
                            <div className="row ">
                                <div className='col-md-12'>
                                    <Image className='headerLogo' src={logo_framework_titulo} alt='logo avap framework' />
                                </div>
                            </div>
                            <div className="row res-margin">
                                <div className="col-md-12 ">
                                    <h1 className='headingText mt-sm-3 mt-2px'>The future of API technology</h1>
                                </div>
                                <div className="col-md-12">
                                    <h1 className='headingText mt-18px'><span className='textBold'>Complete</span> lifecycle <span className='textBold'>control.</span></h1>
                                </div>
                                <div className="col-md-12">
                                    <h1 className='subheadingText'>Virtuality and Artificial Intelligence at its CORE</h1>
                                </div>
                            </div>
                        </div>
                    </header>
                    <section id="avapIdBanner" className="w-100 bg-division">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 my-5">
                                    <p className='txt-banner'>  Ready to transform API development?</p>
                                    <p className='subtxt-banner'> Register at AVAP ID</p>
                                    <button
                                        onClick={() => { history.push('/Register') }}
                                        className='greenbutton m-auto d-table'
                                        style={{ marginTop: '20px' }}
                                    >
                                        Create my AVAP ID
                                    </button>
                                </div>
                            </div>
                        </div>

                    </section>
                    <section id="soluciones" className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className='landing-title text-center mt-5'>The total <br /> API solutionss</h3>
                            </div>
                            <div className="col-md-12">
                                <p className='landing-subtitle text-center'>
                                    Next-gen technology with AI embedded in its DNA, offering scalability and profitability through its Virtuality.

                                </p>
                            </div>

                        </div>
                        <div className="row mt-6">
                            <div className="col-md-4">
                                <Image src={lenguaje} />
                            </div>
                            <div className="col-md-8">
                                <h4 className='landing-conceptTitle'>High-Level Programming Language</h4>
                                <p className='landing-conceptText'>Its primary focus is on API creation, implementing fundamental processes like language commands and managing input and output data structures as native data structures.</p>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-4">
                                <Image src={virtualidad} />
                            </div>
                            <div className="col-md-8">
                                <h4 className='landing-conceptTitle'>Virtuality</h4>
                                <p className='landing-conceptText'>AVAP’s virtuality comes from its dynamic definitions hosted in the cloud. Therefore, during development, the language server only reads commands, retrieves these definitions, and composes a Virtual Code that the virtual machine (parser) executes.</p>
                            </div>
                        </div>
                        <div className="row mt-5 mb-7">
                            <div className="col-md-4">
                                <Image src={inteligencia} />
                            </div>
                            <div className="col-md-8">
                                <h4 className='landing-conceptTitle'>Intelligent Language</h4>
                                <p className='landing-conceptText'>Artificial intelligence at its core: the developer presents a problem and obtains the solution through specific commands and natural language.</p>
                            </div>
                        </div>
                    </section >
                    <section id="bannerProductivity" className='W-100 headbg' style={{ backgroundImage: `url(${cabecera2})` }}>
                        <div className="contentWrapper">
                            <div className="absoluteContainer">
                                <h2 className="landing-heroTitle">
                                    Enhance <span className='textBold'>productivity</span> and maximize <span className='textBold'>ROI</span> for every project
                                </h2>
                                <p className='landing-heroText'>
                                    Efficient, intelligent, and agile API management for your team and operations.
                                </p>


                            </div>
                        </div>
                    </section>
                    <section className='w-100 bg-division'>
                        <div id="carrusel" className='container'>
                            <div className="row">
                                <div className="col-md-12">
                                    <h3 className='landing-title text-center mt-5'>
                                        Products and Services
                                    </h3>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-sm-12 col-md-9 ">
                                    <p className='landing-subtitle text-center'>
                                        AVAP Technology: Complete Lifecycle Management for Your APIs – Creation, Publication, Utilization, and Maintenance.
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">

                                    <div id="carruselWrapper">
                                        <div
                                            style={{
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                                backgroundColor: 'white',
                                            }}>
                                            <div>
                                                <Image id='logoCarrusel' src={logo_framework_producto} className='img-fluid mb-4 pt-4' alt="Logo Avap Framework" />
                                            </div>
                                            <div>
                                                <div style={{ backgroundColor: '#FFFFFF', width: '100%', height: '4px' }}></div>
                                                <Image className='hideLink' onClick={() => { cleanAll(); setavapBut(true) }} src={avapBut ? botonAvapActivo : botonAvap} style={{ cursor: 'pointer', width: '16.2%', marginRight: '0.5%' }} />
                                                <Image className='hideLink' onClick={() => { cleanAll(); setdsBut(true) }} src={dsBut ? botonDsActivo : botonDs} style={{ cursor: 'pointer', width: '16.2%', marginRight: '0.5%' }} />
                                                <Image className='hideLink' onClick={() => { cleanAll(); setvsBut(true) }} src={vsBut ? botonVspActivo : botonVs} style={{ cursor: 'pointer', width: '16.2%', marginRight: '0.5%' }} />
                                                <Image className='hideLink' onClick={() => { cleanAll(); setgBut(true) }} src={gBut ? botonGActivo : botonG} style={{ cursor: 'pointer', width: '16.2%', marginRight: '0.5%' }} />
                                                <Image className='hideLink' onClick={() => { cleanAll(); setcloudBut(true) }} src={cloudBut ? botonCloudActivo : botonCloud} style={{ cursor: 'pointer', width: '16.2%', marginRight: '0.5%' }} />
                                                <Image className='hideLink' onClick={() => { cleanAll(); setcnBut(true) }} src={CnBut ? botonCnpActivo : botonCnp} style={{ cursor: 'pointer', width: '16.2%' }} />



                                                <Image className='showLink' onClick={() => { cleanAll(); setavapBut(true) }} src={avapBut ? res_botonAvapActivo : res_botonAvap} style={{ cursor: 'pointer', width: '16.2%', marginRight: '0.5%' }} />
                                                <Image className='showLink' onClick={() => { cleanAll(); setdsBut(true) }} src={dsBut ? res_botonDsActivo : res_botonDs} style={{ cursor: 'pointer', width: '16.2%', marginRight: '0.5%' }} />
                                                <Image className='showLink' onClick={() => { cleanAll(); setvsBut(true) }} src={vsBut ? res_botonVspActivo : res_botonVs} style={{ cursor: 'pointer', width: '16.2%', marginRight: '0.5%' }} />
                                                <Image className='showLink' onClick={() => { cleanAll(); setgBut(true) }} src={gBut ? res_botonGActivo : res_botonG} style={{ cursor: 'pointer', width: '16.2%', marginRight: '0.5%' }} />
                                                <Image className='showLink' onClick={() => { cleanAll(); setcloudBut(true) }} src={cloudBut ? res_botonCloudActivo : res_botonCloud} style={{ cursor: 'pointer', width: '16.2%', marginRight: '0.5%' }} />
                                                <Image className='showLink' onClick={() => { cleanAll(); setcnBut(true) }} src={CnBut ? res_botonCnpActivo : res_botonCnp} style={{ cursor: 'pointer', width: '16.2%' }} />

                                            </div>


                                            {
                                                avapBut &&
                                                <div style={{ justifyContent: 'start', textAlign: 'start' }}>
                                                    <div>
                                                        <Image src={AVAPLanguage} className='img-fluid logo' />
                                                    </div>
                                                    <p className='infoCarrusel' >
                                                        AVAP (Advanced Virtual API Programming) is a high-level virtual programming language designed and created for API development. Its virtual nature enables continuous code improvement, making it highly scalable and cost-effective.
                                                        AVAP, including its language specifications, interpreter, and language server, marks the beginning of a new generation of programming languages where artificial intelligence becomes a fundamental part of the language, allowing developers to focus on problem-solving tasks.
                                                    </p>
                                                    <div style={{ marginLeft: '80px', marginTop: '43px' }}>
                                                        <button onClick={() => { history.push('/avap') }} className='orangebutton inline-block mb-5 mt-3'>Learn More</button>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                dsBut &&
                                                <div style={{ justifyContent: 'start', textAlign: 'start' }}>
                                                    <div>
                                                        <Image className='img-fluid logo' src={AVAPDS} />
                                                    </div>
                                                    <p className='infoCarrusel' >
                                                        AVAP Dev Studio is an IDE that offers complete project management, API development, code maintenance, and API publication capabilities. It enhances the development process with AI-powered coding through extensions, facilitating a collaborative experience through team management with co-coding and chat management features.

                                                        AVAP Dev Studio allows direct execution of AVAP code through integration with a language server, maximizing the benefits of AVAP Framework with its powerful tools and functionalities.

                                                    </p>
                                                    <div style={{ marginLeft: '80px', marginTop: '43px' }}>
                                                        <button onClick={() => { history.push('/devstudio') }} className='greenbutton mb-5 mt-3'>Learn More</button>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                vsBut &&
                                                <div style={{ justifyContent: 'start', textAlign: 'start' }}>
                                                    <div>
                                                        <Image className='img-fluid logo' src={AVAPVirtualServer} />
                                                    </div>
                                                    <p className='infoCarrusel' >
                                                        Manage and publish your APIs in both development and production environments using AVS (AVAP Virtual Server).

                                                        Integrated with the AVAP code repository in the cloud, AVS can manage multiple versions of each API code developed in AVAP, and conduct necessary tests for deployment and publication across diverse environments.
                                                        AVS is compatible with all language and API servers.

                                                    </p>
                                                    <div style={{ marginLeft: '80px', marginTop: '43px' }}>
                                                        <button onClick={() => { history.push('/avs') }} className='redbutton mb-5 mt-3'>Learn More</button>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                gBut &&
                                                <div style={{ justifyContent: 'start', textAlign: 'start' }}>
                                                    <div>
                                                        <Image className='img-fluid logo' src={AVAPGateway} />
                                                    </div>
                                                    <p className='infoCarrusel' >
                                                        More powerful than a proxy, it assesses consumption history, scenarios, and established conditions to swiftly and efficiently redirect to different servers. Lua programming drives its functionality.

                                                        AVAP Gateway Advanced is both universal and powerful, boasting compatibility with any language server.

                                                    </p>
                                                    <div style={{ marginLeft: '80px', marginTop: '43px' }}>
                                                        <button onClick={() => { history.push('/gateway') }} className='purplebutton mb-5 mt-3'>Learn More</button>
                                                    </div>
                                                </div>
                                            }

                                            {
                                                cloudBut &&
                                                <div style={{ justifyContent: 'start', textAlign: 'start' }}>
                                                    <div>
                                                        <Image className='logo' src={AVAPCloudLogo} />
                                                    </div>
                                                    <p className='infoCarrusel' >
                                                        Cloud platform designed for users to leverage the full potential of AVAP Framework products and services. Gain flexible and scalable access to advanced technological solutions without the need for local infrastructure.

                                                        AVAP Cloud is a universal and powerful service that enhances your AVAP experience while also being compatible with other programming environments and language servers.

                                                    </p>
                                                    <div style={{ marginLeft: '80px', marginTop: '43px' }}>
                                                        <button onClick={() => { history.push('/cloud') }} className='bluebutton mb-5 mt-3'>Learn More</button>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                CnBut &&
                                                <div style={{ justifyContent: 'start', textAlign: 'start' }}>
                                                    <div>
                                                        <Image className='logo' src={AVAPCollaborative} />
                                                    </div>
                                                    <p className='infoCarrusel' >
                                                        A peer-to-peer platform with exponential growth capacity, allowing developers to harness a complete deployment of AVS (AVAP Virtual Server) and AVAP Interpreter & Server Language for API development and publication.

                                                        AVAP Collaborative Network can assess each API request for direct processing or redirection to another server with lower response time, utilizing dedicated channels for AVAP Virtual Code transmission to amplify virtual machine capacity across language servers.

                                                    </p>
                                                    <div style={{ marginLeft: '80px', marginTop: '43px' }}>
                                                        <button onClick={() => { history.push('/collaborative') }} className='tealbutton mb-5 mt-3'>Learn More</button>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div style={{
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            backgroundColor: 'rgb(240, 240, 240)',
                                            marginTop: '0px',
                                        }}>
                                            <div className='legendFooter pt-5 pb-2'>Explore AVAP Framework,<br />
                                                register at AVAP ID</div>

                                            <div style={{ textAlign: 'end', marginTop: '20px', marginRight: '80px' }} className='pb-7'>
                                                <button onClick={() => { history.push('/Register') }} className='greenbutton float-right'>Create my AVAP ID</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="bannerVirtual" className='W-100 headbg' style={{ backgroundImage: `url(${Virtualizacion})` }}>
                        <div className="contentWrapper">
                            <div className="absoluteContainer">
                                <h2 className="landing-heroTitle">
                                    Uncover the reality of API  <span className='textBold'>virtualization</span>
                                </h2>
                                <p className='landing-heroText'>
                                    AVAP’s language virtualization feature enables you to create virtual APIs right from the start.
                                </p>


                            </div>
                        </div>
                    </section>
                    <section id="frameworkInfo" className='container'>
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className='landing-title text-center mt-5 fixPadding'>  AVAP Framework: A complete API solution that transforms processes and boosts efficiency.</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Image src={productividad} alt="productividad" />
                                <div className='landing-conceptTitle'>Boost productivity</div>
                                <div className='landing-conceptText'>Let your development team focus on solutions while we handle the technical details.                        </div>

                            </div>
                            <div className="col-md-6">
                                <Image src={focoproyectos} alt="foco en proyectos" />
                                <div className='landing-conceptTitle'>Maximize project focus</div>
                                <div className='landing-conceptText'>Forget about deployment details, let us manage the logistics so you can concentrate on making your projects a success.</div>
                            </div>
                        </div>
                        <div className="row mt-sm-5">
                            <div className="col-md-6">
                                <Image src={tiemposlanzamiento} alt="productividad" />
                                <div className='landing-conceptTitle'>Faster time to market</div>
                                <div className='landing-conceptText'>AVAP's language and comprehensive suite of products and services ensure a seamless and efficient API lifecycle.</div>
                            </div>
                            <div className="col-md-6">
                                <Image src={rentabilidad} alt="foco en proyectos" />
                                <div className='landing-conceptTitle'>Higher profitability</div>
                                <div className='landing-conceptText'>Optimize development investment with specialized infrastructure and resources for API deployment and maintenance.</div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div style={{ marginBottom: '80px', justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', marginTop: '80px' }}>
                                    <button onClick={() => { window.open('mailto:sales@101obex.com', '_self') }} className='darkbluebuttton'>Contact Sales</button>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="bannerCollab" className='w-100 headbg' style={{ backgroundImage: `url(${collaborative2})` }} >
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <Image src={AVAPCollaborative} className='pt-5' alt="Virtualidad" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <h3 className='landing-title text-center mt-5 mb-8'>
                                        Welcome to the first democratic computing and API exploitation network. <br /> AVAP Collaborative Network
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="infocollab" className='w-100 bg-division'>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <h1 className='landing-conceptTitle text-center pt-5 pb-4'>
                                        Peer-to-Peer Collaborative Servers
                                    </h1>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='landing-conceptText text-center colorBlack'>
                                        AVAP Collaborative Network is a cloud-based peer-to-peer service that enables developers to share servers, AVS (AVAP Virtual Servers), and AI&LS (AVAP Interpreter and Language Servers). It integrates AVAP language and infrastructure, optimizing code interpretation and processing through advanced evaluation of each request.
                                    </p>
                                </div>
                                <div className="col-md-12 my-5">
                                    <button
                                        onClick={() => { history.push('/collaborative') }}
                                        className='darkbluebuttton'>Try it
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="connect" className='w-100 bg-division'>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className='wrapperContent'>
                                        <Image src={connect} style={{ position: 'relative', marginTop: '0px' }} alt="AVAP Connect" className='img-fluid' />

                                        <div className="customPadding">
                                            <Image src={avapconect} className='logoConnect py-4' alt="AVAP Connect" />
                                            <h1 className='landing-title text-center pt-2 mb-0'>
                                                Learn about all the benefits of AVAP Connect
                                            </h1>
                                            <div style={{ paddingTop: '10px' }} className='landing-conceptTitle text-center colorBlack'>Join the Early Access program</div>

                                            <div style={{ marginLeft: '10%', height: '2px', backgroundColor: '#ebebeb', width: '80%', marginTop: '20px', marginBottom: '30px' }}></div>

                                            <p className='landing-conceptText text-center colorBlack'>
                                                Become an AVAP Framework strategic partner and provide the ultimate comprehensive solution for APIs. AVAP Connect is our global program designed for business partners, offering a unified infrastructure with complete sales tools, support, services, and pre-sale opportunities to drive your business growth.
                                            </p>
                                            <div className='py-5'>
                                                <button
                                                    onClick={() => { window.open('mailto:partners@101obex.com', '_self') }}
                                                    className='darkbluebuttton'>Join Now</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div >




                <div>
                    <FooterMenuAccess green={true} />
                </div>

            </div >


        </BrowserView>


        <MobileView>

     

            <div id='frameworkMain' className='d-flex flex-column' style={{ backgroundColor: '#fafafa' }}>
            <MobileNabvar />
                <div className='mainFont' onClick={() => { handleClick() }}>
                    <header id='mainHeader' className="w-100 mtLead vh-100 headbg" style={{ marginTop: '40px', backgroundImage: `url(${cabecera})`, }} >
                        <div className="container">
                            <div className="row ">
                                <div className='col-md-12'>
                                    <Image className='headerLogo' src={logo_framework_titulo} alt='logo avap framework' />
                                </div>
                            </div>
                            <div className="row res-margin">
                                <div className="col-md-12 ">
                                    <h1 className='headingText mt-sm-3 mt-2px'>The future of API technology</h1>
                                </div>
                                <div className="col-md-12">
                                    <h1 className='headingText mt-18px'><span className='textBold'>Complete</span> lifecycle <span className='textBold'>control.</span></h1>
                                </div>
                                <div className="col-md-12">
                                    <h1 className='subheadingText'>Virtuality and Artificial Intelligence at its CORE</h1>
                                </div>
                            </div>
                        </div>
                    </header>
                    <section id="avapIdBanner" className="w-100 bg-division">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 my-5">
                                    <p className='txt-banner'>  Ready to transform API development?</p>
                                    <p className='subtxt-banner'> Register at AVAP ID</p>
                                    <button
                                        onClick={() => { history.push('/Register') }}
                                        className='greenbutton m-auto d-table'
                                        style={{ marginTop: '20px' }}
                                    >
                                        Create my AVAP ID
                                    </button>
                                </div>
                            </div>
                        </div>

                    </section>
                    <section id="soluciones" className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className='landing-title text-center mt-5'>The total <br /> API solutionss</h3>
                            </div>
                            <div className="col-md-12">
                                <p className='landing-subtitle text-center'>
                                    Next-gen technology with AI embedded in its DNA, offering scalability and profitability through its Virtuality.

                                </p>
                            </div>

                        </div>
                        <div className="row mt-6">
                            <div className="col-md-4">
                                <Image src={lenguaje} />
                            </div>
                            <div className="col-md-8">
                                <h4 className='landing-conceptTitle'>High-Level Programming Language</h4>
                                <p className='landing-conceptText'>Its primary focus is on API creation, implementing fundamental processes like language commands and managing input and output data structures as native data structures.</p>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-4">
                                <Image src={virtualidad} />
                            </div>
                            <div className="col-md-8">
                                <h4 className='landing-conceptTitle'>Virtuality</h4>
                                <p className='landing-conceptText'>AVAP’s virtuality comes from its dynamic definitions hosted in the cloud. Therefore, during development, the language server only reads commands, retrieves these definitions, and composes a Virtual Code that the virtual machine (parser) executes.</p>
                            </div>
                        </div>
                        <div className="row mt-5 mb-7">
                            <div className="col-md-4">
                                <Image src={inteligencia} />
                            </div>
                            <div className="col-md-8">
                                <h4 className='landing-conceptTitle'>Intelligent Language</h4>
                                <p className='landing-conceptText'>Artificial intelligence at its core: the developer presents a problem and obtains the solution through specific commands and natural language.</p>
                            </div>
                        </div>
                    </section >
                    <section id="bannerProductivity" className='W-100 headbg' style={{ backgroundImage: `url(${cabecera2})` }}>
                        <div className="contentWrapper">
                            <div className="absoluteContainer">
                                <h2 className="landing-heroTitle">
                                    Enhance <span className='textBold'>productivity</span> and maximize <span className='textBold'>ROI</span> for every project
                                </h2>
                                <p className='landing-heroText'>
                                    Efficient, intelligent, and agile API management for your team and operations.
                                </p>


                            </div>
                        </div>
                    </section>
                    <section className='w-100 bg-division'>
                        <div id="carrusel" className='container'>
                            <div className="row">
                                <div className="col-md-12">
                                    <h3 className='landing-title text-center mt-5'>
                                        Products and Services
                                    </h3>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-sm-12 col-md-9 ">
                                    <p className='landing-subtitle text-center'>
                                        AVAP Technology: Complete Lifecycle Management for Your APIs – Creation, Publication, Utilization, and Maintenance.
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">

                                    <div id="carruselWrapper">
                                        <div
                                            style={{
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                                backgroundColor: 'white',
                                            }}>
                                            <div>
                                                <Image id='logoCarrusel' src={logo_framework_producto} className='img-fluid mb-4 pt-4' alt="Logo Avap Framework" />
                                            </div>
                                            <div>
                                                <div style={{ backgroundColor: '#FFFFFF', width: '100%', height: '4px' }}></div>
                                                <Image className='hideLink' onClick={() => { cleanAll(); setavapBut(true) }} src={avapBut ? botonAvapActivo : botonAvap} style={{ cursor: 'pointer', width: '16.2%', marginRight: '0.5%' }} />
                                                <Image className='hideLink' onClick={() => { cleanAll(); setdsBut(true) }} src={dsBut ? botonDsActivo : botonDs} style={{ cursor: 'pointer', width: '16.2%', marginRight: '0.5%' }} />
                                                <Image className='hideLink' onClick={() => { cleanAll(); setvsBut(true) }} src={vsBut ? botonVspActivo : botonVs} style={{ cursor: 'pointer', width: '16.2%', marginRight: '0.5%' }} />
                                                <Image className='hideLink' onClick={() => { cleanAll(); setgBut(true) }} src={gBut ? botonGActivo : botonG} style={{ cursor: 'pointer', width: '16.2%', marginRight: '0.5%' }} />
                                                <Image className='hideLink' onClick={() => { cleanAll(); setcloudBut(true) }} src={cloudBut ? botonCloudActivo : botonCloud} style={{ cursor: 'pointer', width: '16.2%', marginRight: '0.5%' }} />
                                                <Image className='hideLink' onClick={() => { cleanAll(); setcnBut(true) }} src={CnBut ? botonCnpActivo : botonCnp} style={{ cursor: 'pointer', width: '16.2%' }} />



                                                <Image className='showLink' onClick={() => { cleanAll(); setavapBut(true) }} src={avapBut ? res_botonAvapActivo : res_botonAvap} style={{ cursor: 'pointer', width: '16.2%', marginRight: '0.5%' }} />
                                                <Image className='showLink' onClick={() => { cleanAll(); setdsBut(true) }} src={dsBut ? res_botonDsActivo : res_botonDs} style={{ cursor: 'pointer', width: '16.2%', marginRight: '0.5%' }} />
                                                <Image className='showLink' onClick={() => { cleanAll(); setvsBut(true) }} src={vsBut ? res_botonVspActivo : res_botonVs} style={{ cursor: 'pointer', width: '16.2%', marginRight: '0.5%' }} />
                                                <Image className='showLink' onClick={() => { cleanAll(); setgBut(true) }} src={gBut ? res_botonGActivo : res_botonG} style={{ cursor: 'pointer', width: '16.2%', marginRight: '0.5%' }} />
                                                <Image className='showLink' onClick={() => { cleanAll(); setcloudBut(true) }} src={cloudBut ? res_botonCloudActivo : res_botonCloud} style={{ cursor: 'pointer', width: '16.2%', marginRight: '0.5%' }} />
                                                <Image className='showLink' onClick={() => { cleanAll(); setcnBut(true) }} src={CnBut ? res_botonCnpActivo : res_botonCnp} style={{ cursor: 'pointer', width: '16.2%' }} />

                                            </div>


                                            {
                                                avapBut &&
                                                <div style={{ justifyContent: 'start', textAlign: 'start' }}>
                                                    <div>
                                                        <Image src={AVAPLanguage} className='img-fluid logo' />
                                                    </div>
                                                    <p className='infoCarrusel' >
                                                        AVAP (Advanced Virtual API Programming) is a high-level virtual programming language designed and created for API development. Its virtual nature enables continuous code improvement, making it highly scalable and cost-effective.
                                                        AVAP, including its language specifications, interpreter, and language server, marks the beginning of a new generation of programming languages where artificial intelligence becomes a fundamental part of the language, allowing developers to focus on problem-solving tasks.
                                                    </p>
                                                    <div style={{ marginLeft: '80px', marginTop: '43px' }}>
                                                        <button onClick={() => { history.push('/avap') }} className='orangebutton inline-block mb-5 mt-3'>Learn More</button>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                dsBut &&
                                                <div style={{ justifyContent: 'start', textAlign: 'start' }}>
                                                    <div>
                                                        <Image className='img-fluid logo' src={AVAPDS} />
                                                    </div>
                                                    <p className='infoCarrusel' >
                                                        AVAP Dev Studio is an IDE that offers complete project management, API development, code maintenance, and API publication capabilities. It enhances the development process with AI-powered coding through extensions, facilitating a collaborative experience through team management with co-coding and chat management features.

                                                        AVAP Dev Studio allows direct execution of AVAP code through integration with a language server, maximizing the benefits of AVAP Framework with its powerful tools and functionalities.

                                                    </p>
                                                    <div style={{ marginLeft: '80px', marginTop: '43px' }}>
                                                        <button onClick={() => { history.push('/devstudio') }} className='greenbutton mb-5 mt-3'>Learn More</button>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                vsBut &&
                                                <div style={{ justifyContent: 'start', textAlign: 'start' }}>
                                                    <div>
                                                        <Image className='img-fluid logo' src={AVAPVirtualServer} />
                                                    </div>
                                                    <p className='infoCarrusel' >
                                                        Manage and publish your APIs in both development and production environments using AVS (AVAP Virtual Server).

                                                        Integrated with the AVAP code repository in the cloud, AVS can manage multiple versions of each API code developed in AVAP, and conduct necessary tests for deployment and publication across diverse environments.
                                                        AVS is compatible with all language and API servers.

                                                    </p>
                                                    <div style={{ marginLeft: '80px', marginTop: '43px' }}>
                                                        <button onClick={() => { history.push('/avs') }} className='redbutton mb-5 mt-3'>Learn More</button>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                gBut &&
                                                <div style={{ justifyContent: 'start', textAlign: 'start' }}>
                                                    <div>
                                                        <Image className='img-fluid logo' src={AVAPGateway} />
                                                    </div>
                                                    <p className='infoCarrusel' >
                                                        More powerful than a proxy, it assesses consumption history, scenarios, and established conditions to swiftly and efficiently redirect to different servers. Lua programming drives its functionality.

                                                        AVAP Gateway Advanced is both universal and powerful, boasting compatibility with any language server.

                                                    </p>
                                                    <div style={{ marginLeft: '80px', marginTop: '43px' }}>
                                                        <button onClick={() => { history.push('/gateway') }} className='purplebutton mb-5 mt-3'>Learn More</button>
                                                    </div>
                                                </div>
                                            }

                                            {
                                                cloudBut &&
                                                <div style={{ justifyContent: 'start', textAlign: 'start' }}>
                                                    <div>
                                                        <Image className='logo' src={AVAPCloudLogo} />
                                                    </div>
                                                    <p className='infoCarrusel' >
                                                        Cloud platform designed for users to leverage the full potential of AVAP Framework products and services. Gain flexible and scalable access to advanced technological solutions without the need for local infrastructure.

                                                        AVAP Cloud is a universal and powerful service that enhances your AVAP experience while also being compatible with other programming environments and language servers.

                                                    </p>
                                                    <div style={{ marginLeft: '80px', marginTop: '43px' }}>
                                                        <button onClick={() => { history.push('/cloud') }} className='bluebutton mb-5 mt-3'>Learn More</button>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                CnBut &&
                                                <div style={{ justifyContent: 'start', textAlign: 'start' }}>
                                                    <div>
                                                        <Image className='logo' src={AVAPCollaborative} />
                                                    </div>
                                                    <p className='infoCarrusel' >
                                                        A peer-to-peer platform with exponential growth capacity, allowing developers to harness a complete deployment of AVS (AVAP Virtual Server) and AVAP Interpreter & Server Language for API development and publication.

                                                        AVAP Collaborative Network can assess each API request for direct processing or redirection to another server with lower response time, utilizing dedicated channels for AVAP Virtual Code transmission to amplify virtual machine capacity across language servers.

                                                    </p>
                                                    <div style={{ marginLeft: '80px', marginTop: '43px' }}>
                                                        <button onClick={() => { history.push('/collaborative') }} className='tealbutton mb-5 mt-3'>Learn More</button>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div style={{
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            backgroundColor: 'rgb(240, 240, 240)',
                                            marginTop: '0px',
                                        }}>
                                            <div className='legendFooter pt-5 pb-2'>Explore AVAP Framework,<br />
                                                register at AVAP ID</div>

                                            <div style={{ textAlign: 'end', marginTop: '20px', marginRight: '80px' }} className='pb-7'>
                                                <button onClick={() => { history.push('/Register') }} className='greenbutton float-right'>Create my AVAP ID</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="bannerVirtual" className='W-100 headbg' style={{ backgroundImage: `url(${Virtualizacion})` }}>
                        <div className="contentWrapper">
                            <div className="absoluteContainer">
                                <h2 className="landing-heroTitle">
                                    Uncover the reality of API  <span className='textBold'>virtualization</span>
                                </h2>
                                <p className='landing-heroText'>
                                    AVAP’s language virtualization feature enables you to create virtual APIs right from the start.
                                </p>


                            </div>
                        </div>
                    </section>
                    <section id="frameworkInfo" className='container'>
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className='landing-title text-center mt-5 fixPadding'>  AVAP Framework: A complete API solution that transforms processes and boosts efficiency.</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Image src={productividad} alt="productividad" />
                                <div className='landing-conceptTitle'>Boost productivity</div>
                                <div className='landing-conceptText'>Let your development team focus on solutions while we handle the technical details.                        </div>

                            </div>
                            <div className="col-md-6">
                                <Image src={focoproyectos} alt="foco en proyectos" />
                                <div className='landing-conceptTitle'>Maximize project focus</div>
                                <div className='landing-conceptText'>Forget about deployment details, let us manage the logistics so you can concentrate on making your projects a success.</div>
                            </div>
                        </div>
                        <div className="row mt-sm-5">
                            <div className="col-md-6">
                                <Image src={tiemposlanzamiento} alt="productividad" />
                                <div className='landing-conceptTitle'>Faster time to market</div>
                                <div className='landing-conceptText'>AVAP's language and comprehensive suite of products and services ensure a seamless and efficient API lifecycle.</div>
                            </div>
                            <div className="col-md-6">
                                <Image src={rentabilidad} alt="foco en proyectos" />
                                <div className='landing-conceptTitle'>Higher profitability</div>
                                <div className='landing-conceptText'>Optimize development investment with specialized infrastructure and resources for API deployment and maintenance.</div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div style={{ marginBottom: '80px', justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', marginTop: '80px' }}>
                                    <button onClick={() => { window.open('mailto:sales@101obex.com', '_self') }} className='darkbluebuttton'>Contact Sales</button>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="bannerCollab" className='w-100 headbg' style={{ backgroundImage: `url(${collaborative2})` }} >
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <Image src={AVAPCollaborative} className='pt-5' alt="Virtualidad" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <h3 className='landing-title text-center mt-5 mb-8'>
                                        Welcome to the first democratic computing and API exploitation network. <br /> AVAP Collaborative Network
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="infocollab" className='w-100 bg-division'>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <h1 className='landing-conceptTitle text-center pt-5 pb-4'>
                                        Peer-to-Peer Collaborative Servers
                                    </h1>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='landing-conceptText text-center colorBlack'>
                                        AVAP Collaborative Network is a cloud-based peer-to-peer service that enables developers to share servers, AVS (AVAP Virtual Servers), and AI&LS (AVAP Interpreter and Language Servers). It integrates AVAP language and infrastructure, optimizing code interpretation and processing through advanced evaluation of each request.
                                    </p>
                                </div>
                                <div className="col-md-12 my-5">
                                    <button
                                        onClick={() => { history.push('/collaborative') }}
                                        className='darkbluebuttton'>Try it
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="connect" className='w-100 bg-division'>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className='wrapperContent'>
                                        <Image src={connect} style={{ position: 'relative', marginTop: '0px' }} alt="AVAP Connect" className='img-fluid' />

                                        <div className="customPadding">
                                            <Image src={avapconect} className='logoConnect py-4' alt="AVAP Connect" />
                                            <h1 className='landing-title text-center pt-2 mb-0'>
                                                Learn about all the benefits of AVAP Connect
                                            </h1>
                                            <div style={{ paddingTop: '10px' }} className='landing-conceptTitle text-center colorBlack'>Join the Early Access program</div>

                                            <div style={{ marginLeft: '10%', height: '2px', backgroundColor: '#ebebeb', width: '80%', marginTop: '20px', marginBottom: '30px' }}></div>

                                            <p className='landing-conceptText text-center colorBlack'>
                                                Become an AVAP Framework strategic partner and provide the ultimate comprehensive solution for APIs. AVAP Connect is our global program designed for business partners, offering a unified infrastructure with complete sales tools, support, services, and pre-sale opportunities to drive your business growth.
                                            </p>
                                            <div className='py-5'>
                                                <button
                                                    onClick={() => { window.open('mailto:partners@101obex.com', '_self') }}
                                                    className='darkbluebuttton'>Join Now</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div >



            </div >





            <div>
                <FooterMenuAccessMobile green={true} />
            </div>
        </MobileView >

    </>



)
}

export default NewLanding;