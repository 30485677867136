import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { Row, Col, Navbar, Container, Nav, Image, NavDropdown } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import './NewLandingFramework.css'
import FooterMenu from '../../components/functionalComponents/DashboardLayout/FooterMenuAccess';
import Logo from '../../assets/img/../../assets/img/AVAP_ID_horizontal.png';
import icon_name from '../../assets/img/newDashboard/icon_name.png';
import icono_Infinito from '../../assets/img/newDashboard/icono-Infinito.png';
import logo_communities from '../../assets/img/newDashboard/logo_communities.png';
import logo_alexandria from '../../assets/img/newDashboard/logo-alexandria.png';
import logo_Connect from '../../assets/img/newDashboard/logo-Connect.png';
import Logo101Obex from '../../assets/img/newDashboard/icono_Framework.png';
import logo_framework from '../../assets/img/avap_logo.png'

import logo_framework_producto from '../../assets/img/AVAP_Framework_productoServicio.png'

import lenguaje from '../../assets/img/lenguaje.png'
import virtualidad from '../../assets/img/virtualidad.png'
import inteligencia from '../../assets/img/inteligencia.png'

import ds_1 from '../../assets/img/icons/DevStudio_01.svg'
import ds_2 from '../../assets/img/icons/DevStudio_02.svg'
import ds_3 from '../../assets/img/icons/DevStudio_03.svg'
import ds_4 from '../../assets/img/icons/DevStudio_04.svg'
import ds_5 from '../../assets/img/icons/DevStudio_05.svg'
import ds_6 from '../../assets/img/icons/DevStudio_06.svg'

import logo_framework_titulo from '../../assets/img/AVAP_DS_P.png'
import cabecera from '../../assets/img/foto_DS.png'
import cabecera2 from '../../assets/img/2cabecera.png'
import FooterMenuAccess from '../../components/functionalComponents/DashboardLayout/FooterMenu';
import FooterMenuAccessMobile from '../../components/functionalComponents/DashboardLayout/FooterMenuMobile';
import MobileNavbar from '../../components/functionalComponents/baseLayout/mobileNavbar';
import BrowserNavbar from '../../components/functionalComponents/baseLayout/browserNavbar';
import botonAvapActivo from '../../assets/img/boton_avap_activo.png'
import botonCloudActivo from '../../assets/img/boton_cloud_activo.png'
import botonDsActivo from '../../assets/img/boton_ds_activo.png'
import botonCnpActivo from '../../assets/img/boton_cn_activo.png'
import botonGActivo from '../../assets/img/boton_g_activo.png'
import botonVspActivo from '../../assets/img/boton_vs_activo.png'

import botonAvap from '../../assets/img/boton_avap.png'
import botonCloud from '../../assets/img/boton_cloud.png'
import botonDs from '../../assets/img/boton_ds.png'
import botonCnp from '../../assets/img/boton_cn.png'
import botonG from '../../assets/img/boton_g.png'
import botonVs from '../../assets/img/boton_vs.png'

import AVAPLanguage from '../../assets/img/AVAPLenguaje.png';
import AVAPVirtualServer from '../../assets/img/AVAPVS.png';
import AVAPGateway from '../../assets/img/AVAPGateway.png';
import AVAPDS from '../../assets/img/AVAPDS.png';
import AVAPCloudLogo from '../../assets/img/AVAPCloud.png';
import AVAPCollaborative from '../../assets/img/AVAPCollaborativeN.png'

import Virtualizacion from '../../assets/img/foto_virtualizacion.png'

import productividad from '../../assets/img/productividad.png'
import rentabilidad from '../../assets/img/rentabilidad.png'
import focoproyectos from '../../assets/img/focoproyectos.png'
import tiemposlanzamiento from '../../assets/img/tiemposlanzamiento.png'

import collaborative2 from '../../assets/img/collaborative2.png'

import icon_collaborative from '../../assets/img/iconCollaborative.png'
import connect from '../../assets/img/connect.png'
import avapconect from '../../assets/img/AVAPConnect.png'
import ReactGA from 'react-ga4';
import config from '../../configs/appConfig.json'
import favicon from '../../assets/img/Favicon_AVAP_Framework.png'
import vineta_CN from '../../assets/img/vineta_DS.png'

import gafico_cn_i from '../../assets/img/grafico_vscode_i.png'
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../../components/mobileComponents/Disclaimer';
import TopMenuBar from './TopMenuBar';

type DashProps = {
    sider: any
};



const NewLandingDevStudio = () => {
    let { path, url } = useRouteMatch();

    const history = useHistory();

    const [avapBut, setavapBut] = useState(true);
    const [vsBut, setvsBut] = useState(false);
    const [gBut, setgBut] = useState(false);
    const [dsBut, setdsBut] = useState(false);
    const [cloudBut, setcloudBut] = useState(false);
    const [CnBut, setcnBut] = useState(false);

    const [menu1, setmenu1] = useState(false);
    const [menu2, setmenu2] = useState(false);
    const [menu3, setmenu3] = useState(false);
    const [menu4, setmenu4] = useState(false);
    const [menu5, setmenu5] = useState(false);


    const cleanAll = () => {
        setavapBut(false);
        setvsBut(false);
        setgBut(false);
        setdsBut(false);
        setcloudBut(false);
        setcnBut(false);
    }

    const cleanMenuAll = () => {
        setmenu1(false);
        setmenu2(false);
        setmenu3(false);
        setmenu4(false);
        setmenu5(false);
    }

    useEffect(() => {
        // favicon
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = favicon; // Usa la ruta importada directamente
        document.head.appendChild(link);

        // favicon
        return () => {
            document.head.removeChild(link);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        //ReactGA.initialize('G-MQZQJN7TQL');
        if (config.url != 'https://avs-primary-pre.101obex.mooo.com') {
            ReactGA.initialize('G-MQZQJN7TQL');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Framework' });
        }

    }, []);


    const handleSignInClick = () => {
        // Aquí puedes realizar cualquier acción antes de redireccionar, si es necesario

        // Redirecciona a otra vista (por ejemplo, '/dashboard')
        history.push('/login');
    };

    const navbarRef = useRef(null);

    const handleClick = () => {
        // Llama a la función cleanMenuAll del componente BrowserNavbar
        if (navbarRef.current) {
            navbarRef.current.cleanMenuAll(); // O también navbarRef.current.cleanAll();
        }
    };




    return (
        <>
            <BrowserView>

                <div className='d-flex flex-column' style={{ backgroundColor: '#ffffff' }} >

                <BrowserNavbar  ref={navbarRef}/>


                    <div id='mobileDevStudio' className="wrapper"  onClick={() => { handleClick() }} >

                        <header className='w-100 headbanner mt-5' style={{ backgroundImage: `url(${cabecera})`, }} >
                        </header>
                        <section id='intro' className='container'>
                            <div className="row">
                                <div className="col-md-12">
                                    <Image src={logo_framework_titulo} className='reslogo' alt="Logo" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <h1 className='text-center hero-title'>
                                        Power up your APIs with AVAP Dev Studio propel your development to
                                        the next level
                                    </h1>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='bodyText text-center mb-5  mt-5'>
                                        AVAP Dev Studio is an IDE that offers complete project management, API develoment, code maintenance,
                                        and API publication capabilities. It enhances the development process with AI-powered coding through
                                        extensions, facilitating a collaborative experience through team management with co-coding and chat management
                                        features. AVAP Dev Studio allows direct execution of AVAP code through integration with a language server,
                                        maximizing the benefits of AVAP Framework with its powerful tools and functionalities.

                                    </p>

                                    <button onClick={() => { history.push('/Register') }} className='greenbutton m-auto  hide-sm d-md-table'> Download</button>
                                </div>
                            </div>

                        </section>
                        <section id='items' className='w-100 bg-division mt-5'>
                            <div className="container">
                                <div className="row mb-5">
                                    <div className="col-md-2">
                                        <Image className='mt-5 dataicon' src={ds_1} width="" />
                                    </div>
                                    <div className="col-md-10">
                                        <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold  title pt-md-5 pt-sm-0'>Complete API support</div>
                                        <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                            Manage the creation of AVAP code repositories and oversee the publication of API versions across multiple environments.
                                            Simplify the management and control of unit testing for deployment in these environments.
                                        </div>

                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-md-2">
                                        <Image src={ds_2} className='dataicon' width="" />
                                    </div>
                                    <div className="col-md-10">
                                        <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title '>Multi-language support</div>
                                        <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>\
                                            AVAP Dev Studio offers linters and code completions not only for AVAP but also for the most popular programming languages. Additionally,
                                            it includes GIT service for managing APIs and integrates seamlessly with GitHub.
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-md-2">
                                        <Image src={ds_3} className='dataicon' width="" />
                                    </div>
                                    <div className="col-md-10">
                                        <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>Copilot AI</div>
                                        <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                            Artificial intelligence provides assistance directly within the development environment,
                                            analyzing code and offering real-time modifications. It also features an integrated no-code/low-code tool.
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-md-2">
                                        <Image src={ds_4} className='dataicon' width="" /></div>
                                    <div className="col-md-10">
                                        <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>
                                            Constant integration cycle
                                        </div>
                                        <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                            Manages the creation of repositories for AVAP code versions.
                                        </div>

                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-md-2">
                                        <Image src={ds_5} className='dataicon' width="" />
                                    </div>
                                    <div className="col-md-10">
                                        <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>
                                            Syntax analysis of the language</div>
                                        <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>

                                            Syntax analysis of the language
                                            Ability to examine and understand the source code in real time.
                                        </div>

                                    </div>
                                </div>

                                <div className="row pb-7">
                                    <div className="col-md-2">
                                        <Image src={ds_6} className='dataicon' width="" />
                                    </div>
                                    <div className="col-md-10">
                                        <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>
                                            Collaborative development</div>
                                        <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                            Facilitates collaborative programming and the management of rooms and chats, simplifying the administration of development teams.
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>


                        <div id='benefictsTab' className="container-fluid responsiveSpace mt-5 pb-5">
                        <div className="row">
                                <div className="col-md-12">
                                    <h1 className='hero-title text-center'>
                                        Top Dev Studio <span>Benefits</span>
                                    </h1>
                                </div>
                                <div className="col-md-12">
                                    <p className='title'>Virtual API execution process</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 order-md-2 order-sm-1">
                                    <Image src={gafico_cn_i} style={{ width: '100%', padding: "10px" }} width="" />
                                </div>
                                <div className="col-md-6 mt-3  order-md-1 order-sm-2">
                                    <div className='landingFont3b m-auto' style={{ marginTop: '20px', width: '90%', }}>
                                        <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                        Single environment that optimizes the API lifecycle, including publication and maintenance.
                                    </div>

                                    <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                        <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                        Visual API creation, enhancing development speed and design accuracy.
                                    </div>

                                    <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                        <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                        Maintenance of AVAP code versions, providing order, control, and security to the development process.
                                    </div>

                                    <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                        <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                        Increased productivity.
                                    </div>

                                    <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                        <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                        Enhanced collaboration and better management of development teams.
                                    </div>

                                    <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                        <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                        Expedited testing processes, optimizing efficiency and ensuring faster, more reliable API implementation.
                                    </div>

                                    <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                        <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                        Artificial Intelligence applied pre-development, enabling code translation based on programmer 
                                        input for enhanced efficiency and accuracy.
                                    </div>

                                </div>
                            </div>
                        </div>




                    </div>


                    <div>
                        <FooterMenuAccess green={true} />
                    </div>
                </div>
            </BrowserView>
            <MobileView>
                <MobileNavbar />
                {/* <Disclaimer /> */}

                <div id='mobileDevStudio' className="wrapper" >

                    <header className='w-100 headbanner mt-5' style={{ backgroundImage: `url(${cabecera})`, }} >
                    </header>
                    <section id='intro' className='container'>
                        <div className="row">
                            <div className="col-md-12">
                                <Image src={logo_framework_titulo} className='reslogo' alt="Logo" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className='text-center hero-title'>
                                    Power up your APIs with AVAP Dev Studio propel your development to
                                    the next level
                                </h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className='bodyText text-center mb-5  mt-5'>
                                    AVAP Dev Studio is an IDE that offers complete project management, API develoment, code maintenance,
                                    and API publication capabilities. It enhances the development process with AI-powered coding through
                                    extensions, facilitating a collaborative experience through team management with co-coding and chat management
                                    features. AVAP Dev Studio allows direct execution of AVAP code through integration with a language server,
                                    maximizing the benefits of AVAP Framework with its powerful tools and functionalities.

                                </p>

                                {/*<button onClick={() => { history.push('/Register') }} className='greenbutton m-auto d-table'> Download</button>*/}
                            </div>
                        </div>

                    </section>
                    <section id='items' className='w-100 bg-division mt-5'>
                        <div className="container">
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image className='mt-5 dataicon' src={ds_1}  width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold  title'>Complete API support</div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        Manage the creation of AVAP code repositories and oversee the publication of API versions across multiple environments.
                                        Simplify the management and control of unit testing for deployment in these environments.
                                    </div>

                                </div>
                            </div>
                            <div className=" mb-5">
                                <div className="col-md-2">
                                    <Image src={ds_2} className='dataicon' width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title '>Multi-language support</div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>\
                                        AVAP Dev Studio offers linters and code completions not only for AVAP but also for the most popular programming languages. Additionally,
                                        it includes GIT service for managing APIs and integrates seamlessly with GitHub.
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image src={ds_3} className='dataicon' width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>Copilot AI</div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        Artificial intelligence provides assistance directly within the development environment,
                                        analyzing code and offering real-time modifications. It also features an integrated no-code/low-code tool.
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image src={ds_4} className='dataicon' width="" /></div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>
                                        Constant integration cycle
                                    </div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        Manages the creation of repositories for AVAP code versions.
                                    </div>

                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image src={ds_5} className='dataicon' width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>
                                        Syntax analysis of the language</div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>

                                        Syntax analysis of the language
                                        Ability to examine and understand the source code in real time.
                                    </div>

                                </div>
                            </div>

                            <div className="row pb-7">
                                <div className="col-md-2">
                                    <Image src={ds_6} className='dataicon' width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>
                                        Collaborative development</div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        Facilitates collaborative programming and the management of rooms and chats, simplifying the administration of development teams.
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="beneficts" className='container mt-5 pb-5'>
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className='hero-title text-center'>
                                    Top Dev Studio <span>Benefits</span>
                                </h1>
                            </div>
                            <div className="col-md-12">
                                <p className='title'>Virtual API execution process</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Image src={gafico_cn_i} style={{ width: '100%', padding: "10px" }} width="" />
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className='landingFont3b m-auto' style={{ marginTop: '20px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Single environment that optimizes the API lifecycle, including publication and maintenance.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Visual API creation, enhancing development speed and design accuracy.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Maintenance of AVAP code versions, providing order, control, and security to the development process.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Increased productivity.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Enhanced collaboration and better management of development teams.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Expedited testing processes, optimizing efficiency and ensuring faster, more reliable API implementation.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Artificial Intelligence applied pre-development, enabling code translation based on programmer input for enhanced efficiency and accuracy.
                                </div>

                            </div>
                        </div>
                    </section>




                </div>


                <div>
                    <FooterMenuAccessMobile green={true} />
                </div>




            </MobileView>
        </>
    )
}

export default NewLandingDevStudio;