import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { Row, Col, Navbar, Container, Nav, Image, NavDropdown } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import './NewLandingFramework.css'
import FooterMenu from '../../components/functionalComponents/DashboardLayout/FooterMenuAccess';
import Logo from '../../assets/img/../../assets/img/AVAP_ID_horizontal.png';
import icon_name from '../../assets/img/newDashboard/icon_name.png';
import icono_Infinito from '../../assets/img/newDashboard/icono-Infinito.png';
import logo_communities from '../../assets/img/newDashboard/logo_communities.png';
import logo_alexandria from '../../assets/img/newDashboard/logo-alexandria.png';
import logo_Connect from '../../assets/img/newDashboard/logo-Connect.png';
import Logo101Obex from '../../assets/img/newDashboard/icono_Framework.png';
import logo_framework from '../../assets/img/avap_logo.png'

import logo_framework_producto from '../../assets/img/AVAP_Framework_productoServicio.png'

import lenguaje from '../../assets/img/lenguaje.png'
import virtualidad from '../../assets/img/virtualidad.png'
import inteligencia from '../../assets/img/inteligencia.png'


import avs_1 from '../../assets/img/icons/VirtualServer_01.svg'
import avs_2 from '../../assets/img/icons/VirtualServer_02.svg'
import avs_3 from '../../assets/img/icons/VirtualServer_03.svg'
import avs_4 from '../../assets/img/icons/VirtualServer_04.svg'
import avs_5 from '../../assets/img/icons/VirtualServer_05.svg'
import avs_6 from '../../assets/img/icons/VirtualServer_06.svg'

import logo_framework_titulo from '../../assets/img/AVAP_VS_P.png'
import cabecera from '../../assets/img/foto_VS.png'
import cabecera2 from '../../assets/img/2cabecera.png'
import FooterMenuAccess from '../../components/functionalComponents/DashboardLayout/FooterMenu';
import FooterMenuAccessMobile from '../../components/functionalComponents/DashboardLayout/FooterMenuMobile';
import MobileNavbar from '../../components/functionalComponents/baseLayout/mobileNavbar';
import BrowserNavbar from '../../components/functionalComponents/baseLayout/browserNavbar';

import botonAvapActivo from '../../assets/img/boton_avap_activo.png'
import botonCloudActivo from '../../assets/img/boton_cloud_activo.png'
import botonDsActivo from '../../assets/img/boton_ds_activo.png'
import botonCnpActivo from '../../assets/img/boton_cn_activo.png'
import botonGActivo from '../../assets/img/boton_g_activo.png'
import botonVspActivo from '../../assets/img/boton_vs_activo.png'

import botonAvap from '../../assets/img/boton_avap.png'
import botonCloud from '../../assets/img/boton_cloud.png'
import botonDs from '../../assets/img/boton_ds.png'
import botonCnp from '../../assets/img/boton_cn.png'
import botonG from '../../assets/img/boton_g.png'
import botonVs from '../../assets/img/boton_vs.png'

import AVAPLanguage from '../../assets/img/AVAPLenguaje.png';
import AVAPVirtualServer from '../../assets/img/AVAPVS.png';
import AVAPGateway from '../../assets/img/AVAPGateway.png';
import AVAPDS from '../../assets/img/AVAPDS.png';
import AVAPCloudLogo from '../../assets/img/AVAPCloud.png';
import AVAPCollaborative from '../../assets/img/AVAPCollaborativeN.png'

import Virtualizacion from '../../assets/img/foto_virtualizacion.png'

import productividad from '../../assets/img/productividad.png'
import rentabilidad from '../../assets/img/rentabilidad.png'
import focoproyectos from '../../assets/img/focoproyectos.png'
import tiemposlanzamiento from '../../assets/img/tiemposlanzamiento.png'

import collaborative2 from '../../assets/img/collaborative2.png'

import icon_collaborative from '../../assets/img/iconCollaborative.png'
import connect from '../../assets/img/connect.png'
import avapconect from '../../assets/img/AVAPConnect.png'
import ReactGA from 'react-ga4';
import config from '../../configs/appConfig.json'
import favicon from '../../assets/img/Favicon_AVAP_Framework.png'

import vineta_CN from '../../assets/img/vineta_vs.png'
import avsTable from '../../assets/img/AvsTable.png'
import kongTable from '../../assets/img/kongTable.png'
import gafico_vscode_i from '../../assets/img/grafico_avs_i.png'
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../../components/mobileComponents/Disclaimer';
import TopMenuBar from './TopMenuBar';

type DashProps = {
    sider: any
};



const NewLandingAVS = () => {
    let { path, url } = useRouteMatch();

    const history = useHistory();

    const [avapBut, setavapBut] = useState(true);
    const [vsBut, setvsBut] = useState(false);
    const [gBut, setgBut] = useState(false);
    const [dsBut, setdsBut] = useState(false);
    const [cloudBut, setcloudBut] = useState(false);
    const [CnBut, setcnBut] = useState(false);

    const [menu1, setmenu1] = useState(false);
    const [menu2, setmenu2] = useState(false);
    const [menu3, setmenu3] = useState(false);
    const [menu4, setmenu4] = useState(false);
    const [menu5, setmenu5] = useState(false);


    const cleanAll = () => {
        setavapBut(false);
        setvsBut(false);
        setgBut(false);
        setdsBut(false);
        setcloudBut(false);
        setcnBut(false);
    }

    const cleanMenuAll = () => {
        setmenu1(false);
        setmenu2(false);
        setmenu3(false);
        setmenu4(false);
        setmenu5(false);
    }


    useEffect(() => {
           // favicon
           const link = document.createElement('link');
           link.rel = 'icon';
           link.href = favicon; // Usa la ruta importada directamente
           document.head.appendChild(link);
       
           // favicon
           return () => {
             document.head.removeChild(link);
           };


        window.scrollTo(0, 0);
        //ReactGA.initialize('G-MQZQJN7TQL');
        if (config.url != 'https://avs-primary-pre.101obex.mooo.com') {
            ReactGA.initialize('G-MQZQJN7TQL');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Framework' });
        }

    }, []);


    const handleSignInClick = () => {
        // Aquí puedes realizar cualquier acción antes de redireccionar, si es necesario

        // Redirecciona a otra vista (por ejemplo, '/dashboard')
        history.push('/login');
    };


    const navbarRef = useRef(null);

    const handleClick = () => {
        // Llama a la función cleanMenuAll del componente BrowserNavbar
        if (navbarRef.current) {
            navbarRef.current.cleanMenuAll(); // O también navbarRef.current.cleanAll();
        }
    };





    return (
        <>


            <BrowserView>
                <BrowserNavbar ref={navbarRef}/>

                <div id='mobileAVs' className="wrapper pt-5" onClick={() => { handleClick() }}>

                    <header className='w-100 headbanner' style={{ backgroundImage: `url(${cabecera})`, }} >
                    </header>
                    <section id='intro' className='container'>
                        <div className="row">
                            <div className="col-md-12">
                                <Image src={logo_framework_titulo} className='reslogo' alt="Logo" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className='text-center hero-title'>
                                    AVAP Virtual Server, a <span className=' dynBold-inverse'>REAL solution</span> for the complete API lifecycle
                                </h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className='bodyText text-center mb-5  mt-5'>
                                    Manage and publish your APIs in both development and production environments using AVS (AVAP Virtual Server). Integrated with the AVAP code repository in the cloud, AVS can manage multiple versions of each API code developed in AVAP, and conduct necessary tests for deployment and publication across diverse environments. AVS is compatible with all language and API servers. Maximize the benefits of AVAP technology without sacrificing your existing APIs.
                                </p>

                                {/* <button onClick={() => { history.push('/Register') }} className='greenbutton m-auto d-table'> Try it</button> */}
                            </div>
                        </div>

                    </section>
                    <section id='items' className='w-100 bg-division mt-5'>
                        <div className="container">
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image className='mt-5 dataicon' src={avs_1}  width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold  title'>
                                        High level of optimization
                                    </div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        Developed using Node.js and KOA.js, AVS is a lightweight, robust, and scalable server solution.
                                    </div>

                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image src={avs_2} className='dataicon' width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>
                                        API lifecycle management
                                    </div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        Automatic execution of unit tests upon publishing different code versions in development and production environments.
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image src={avs_3} className='dataicon' width="" /></div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>
                                        API Exposure Management
                                    </div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        AVAP Virtual Server efficiently and securely manages access and usage of APIs.
                                    </div>

                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image src={avs_4} className='dataicon' width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>
                                        Virtual Server for programmable services
                                    </div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        AVS implements core services for composing complex programmable workflows. Upon startup, it receives instructions and executes them accordingly.
                                    </div>

                                </div>
                            </div>

                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image src={avs_5} className='dataicon' width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>
                                        Intelligent Gateway Management
                                    </div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        Traffic redirection to ensure optimal responses based on performance history, events, and parameters defined by administrators.
                                    </div>

                                </div>
                            </div>
                            <div className="row pb-7">
                                <div className="col-md-2">
                                    <Image src={avs_6} className='dataicon' width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>
                                        Universal Platform
                                    </div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        In addition to the seamlessly connection to AVAP language servers, AVS is compatible with Java, Python and any other API servers.
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="beneficts" className='container-fluid mt-5 pb-5 responsiveSpace'>
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className='hero-title text-center'>
                                    Top AVAP Virtual Server <span className='dynBold'>Benefits</span>
                                </h1>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-6 ">
                                <div className='landingFont3b m-auto' style={{ marginTop: '20px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Optimization of infrastructure investment due to maximum performance.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Management of complex infrastructures through a modular and intelligent server.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Low energy consumption.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Easy installation.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Simplified deployment creation.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Complete API lifecycle management, including publishing and maintenance.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Integration and compatibility with other API servers.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    High-level security.
                                </div>
                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Low latency.
                                </div>
                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Efficient scalability to handle sudden traffic and demand growth.
                                </div>

                            </div>
                            <div className="col-lg-6">

                                <div className="row">
                                    <div className="col-md-12">
                                        <p className='title mb-0 mt-sm-5 mt-md-0'>AVS vs Kong – Gateway response time</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className='bodyText mb-lg-0 mt-sm-3 mt-md-0'>
                                            The test was performed by executing identical API calls on both Gateways using the same database server and backend.
                                            The result shows the time between the handshake and download, providing insight into the Gateway's performance
                                            in processing API requests.
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <Image src={gafico_vscode_i} style={{ width: '100%', padding: "10px" }} width="" className='hide-sm d-md-block' />
                                    </div>
                                    <div className="col-md-6 col-sm-12 dynamicHide mt-sm-3 mt-md-0">
                                        <Image src={avsTable} style={{ width: '100%', padding: "10px" }} width="" className='' />
                                    </div>
                                    <div className="col-md-6 col-sm-12  dynamicHide">
                                        <Image src={kongTable} style={{ width: '100%', padding: "10px" }} width="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>








                </div>


                <div>
                    <FooterMenuAccess green={true} />
                </div>



            </BrowserView>

            <MobileView>
                <MobileNavbar />

                {/* <Disclaimer /> */}

                <div id='mobileAVs' className="wrapper" >

                    <header className='w-100 headbanner mt-5' style={{ backgroundImage: `url(${cabecera})`, }} >
                    </header>
                    <section id='intro' className='container'>
                        <div className="row">
                            <div className="col-md-12">
                                <Image src={logo_framework_titulo} className='reslogo' alt="Logo" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className='text-center hero-title'>
                                    AVAP Virtual Server, a <span className=' textBold'>REAL solution</span> for the complete API lifecycle
                                </h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className='bodyText text-center mb-5  mt-5'>
                                    Manage and publish your APIs in both development and production environments using AVS (AVAP Virtual Server). Integrated with the AVAP code repository in the cloud, AVS can manage multiple versions of each API code developed in AVAP, and conduct necessary tests for deployment and publication across diverse environments. AVS is compatible with all language and API servers. Maximize the benefits of AVAP technology without sacrificing your existing APIs.
                                </p>

                                {/* <button onClick={() => { history.push('/Register') }} className='greenbutton m-auto d-table'> Try it</button> */}
                            </div>
                        </div>

                    </section>
                    <section id='items' className='w-100 bg-division mt-5'>
                        <div className="container">
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image className='mt-5 dataicon' src={avs_1}  width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold  title'>
                                        High level of optimization
                                    </div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        Developed using Node.js and KOA.js, AVS is a lightweight, robust, and scalable server solution.
                                    </div>

                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image src={avs_2} className='dataicon' width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>
                                        API lifecycle management
                                    </div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        Automatic execution of unit tests upon publishing different code versions in development and production environments.
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image src={avs_3} className='dataicon' width="" /></div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>
                                        API Exposure Management
                                    </div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        AVAP Virtual Server efficiently and securely manages access and usage of APIs.
                                    </div>

                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image src={avs_4} className='dataicon' width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>
                                        Virtual Server for programmable services
                                    </div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        AVS implements core services for composing complex programmable workflows. Upon startup, it receives instructions and executes them accordingly.
                                    </div>

                                </div>
                            </div>

                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image src={avs_5} className='dataicon' width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>
                                        Intelligent Gateway Management
                                    </div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        Traffic redirection to ensure optimal responses based on performance history, events, and parameters defined by administrators.
                                    </div>

                                </div>
                            </div>
                            <div className="row pb-7">
                                <div className="col-md-2">
                                    <Image src={avs_6} className='dataicon' width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>
                                        Universal Platform
                                    </div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        In addition to the seamlessly connection to AVAP language servers, AVS is compatible with Java, Python and any other API servers.
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="beneficts" className='container mt-5 pb-5'>
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className='hero-title text-center'>
                                    Top AVAP Virtual Server <span>Benefits</span>
                                </h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mt-3">
                                <div className='landingFont3b m-auto' style={{ marginTop: '20px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Optimization of infrastructure investment due to maximum performance.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Management of complex infrastructures through a modular and intelligent server.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Low energy consumption.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Easy installation.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Simplified deployment creation.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Complete API lifecycle management, including publishing and maintenance.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Integration and compatibility with other API servers.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    High-level security.
                                </div>
                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Low latency.
                                </div>
                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Efficient scalability to handle sudden traffic and demand growth.
                                </div>

                            </div>
                        </div>
                    </section>

                    <section className="w-100 bg-division">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='title pt-5'>AVS vs Kong – Gateway response time</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='bodyText text-center'>
                                        The test was performed by executing identical API calls on both Gateways using the same database server and backend.
                                        The result shows the time between the handshake and download, providing insight into the Gateway's performance
                                        in processing API requests.
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <Image src={avsTable} style={{ width: '100%', padding: "10px" }} width="" />
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <Image src={kongTable} style={{ width: '100%', padding: "10px" }} width="" />
                                </div>
                            </div>
                        </div>
                    </section>



                </div>


                <div>
                    <FooterMenuAccessMobile green={true} />
                </div>




            </MobileView>
        </>
    )
}

export default NewLandingAVS;