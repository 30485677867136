import { Container, Row, Col, Alert, Button } from 'react-bootstrap';
import React, { FunctionComponent } from 'react';
import '../../../assets/css/baseLayout.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderMenu from './HeaderMenu';
import ApiSideBar from './ApiSideBar';
import Footer from '../FooterLayout/FooterMenu';
import CookieMessage from '../../Modals/CookieMessage';
import { useState } from 'react';
import ReactGA from 'react-ga4';
import Terminal from 'terminal-in-react';
import SessionService from '../../../services/SessionsService';
import FooterMenu from '../DashboardLayout/FooterMenuAccess';
//import pseudoFileSystem from 'terminal-in-react-pseudo-file-system-plugin';
//const FileSystemPlugin = pseudoFileSystem();
//import NodeEvalPlugin from 'terminal-in-react-node-eval-plugin';

type BaseProps = {
  sider: any,
  active?: string,
  children: any
};

const BaseLayout: FunctionComponent<BaseProps> = ({ sider, active, children }: BaseProps) => {

  const [thankyou, setThankyou] = useState(false);
  const [goodFeedback, setGood] = useState(true);
  const [badFeedback, setBad] = useState(true);
  const [StyleGood, setStyleGood] = useState({ backgroundColor: '#818881' });
  const [StyleBad, setStyleBad] = useState({ backgroundColor: '#818881' });
  const [hooverGood, sethooverGood] = useState(false);
  const [hooverBad, sethooverBad] = useState(false);
  const [panel, setPanel] = useState(false);




  const BadFeedback = () => {
    setGood(false);
    setThankyou(true);
    setBad(false);
    ReactGA.event({
      category: 'Feedback',
      action: 'Bad',
      label: (window.location.pathname + window.location.search)
    });

  }
  const GoodFeedback = () => {
    setBad(false);
    setThankyou(true);
    setGood(false);
    ReactGA.event({
      category: 'Feedback',
      action: 'Good',
      label: (window.location.pathname + window.location.search)
    });
  }

  const toggleHooverGood = () => {
    if (goodFeedback && badFeedback) {
      if (hooverGood) { setStyleGood({ backgroundColor: '#818881' }); sethooverGood(false); }
      else { setStyleGood({ backgroundColor: '#3e413e' }); sethooverGood(true); }
    }
  }
  const toggleHooverBad = () => {
    if (goodFeedback && badFeedback) {
      if (hooverBad) { setStyleBad({ backgroundColor: '#818881' }); sethooverBad(false); }
      else { setStyleBad({ backgroundColor: '#3e413e' }); sethooverBad(true); }
    }
  }


  const togglePanel = () =>{
    setPanel(!panel);
  }
  const cambio = () => {

    setBad(true);
    setGood(true);
    setThankyou(false);
    setStyleGood({ backgroundColor: '#818881' });
    setStyleBad({ backgroundColor: '#818881' });
    sethooverGood(false);
    sethooverBad(false);

  }

  if (SessionService.eventEmitter){
  SessionService.eventEmitter.once('panel', function() {
    console.log('Paneling');
    if (!SessionService.SCREAMING){
      SessionService.SCREAMING = true;
      
      if (panel){
        
      const elem1 = document.getElementById('pannel') as HTMLDivElement;
      elem1.hidden = true;
      const elem2 = document.getElementById('principal') as HTMLDivElement;
      elem2.style.height='100%'
      } else {
        
        const elem1 = document.getElementById('pannel') as HTMLDivElement;
        elem1.hidden = false;
        const elem2 = document.getElementById('principal') as HTMLDivElement;
        elem2.style.height='80%'
      }

      togglePanel();
      SessionService.SCREAMING = false;
    }
  });
  }

  return (
 

    <><div id='principal' className="text-start d-flex flex-column containerLogin " style={{ height: `${panel ? '75%' : '100%'}` }}>
      <><HeaderMenu /><Row id="content-page" style={{marginRight:"0px"}}>
        <ApiSideBar sider={sider} active={active} cambio={cambio} />
        <Col id="content" className="pt-4 pl-4 pr-4 d-flex flex-column min-vh-100 position-static" >
          {children}
          {/* <Alert key={1} variant='success'>
<span className="feedback-text">Was this page helpful? </span>
<Button style={StyleGood} onMouseEnter={toggleHooverGood} onMouseLeave={toggleHooverGood} disabled={!goodFeedback} onClick={() => { GoodFeedback() }} className="button-feedback">Yes</Button>
<Button style={StyleBad} onMouseEnter={toggleHooverBad} onMouseLeave={toggleHooverBad} disabled={!badFeedback} onClick={() => { BadFeedback() }} className="button-bad-feedback">No</Button>
{thankyou && <span className="thankyou-text">Thank You for your Feedback!</span>}
</Alert> */}
          {/* <div className="border-top mx-3 mt-3 mb-5 mt-auto"></div> */}
          {/* <Row className="justify-content-md-center">
<Col md={{ span: 10 }}>
<Footer />
</Col>
</Row> */}






        </Col>

      </Row><FooterMenu green={true} /><CookieMessage /></>
      {/*} </Container>*/}
    </div><div className="position-fixed" id="pannel" style={{ backgroundColor: 'black', width: '100%', height: `${panel ? '20%' : '0%'}`, overflow: 'hidden', zIndex: 9999999 }}>
        <Terminal></Terminal>
      </div></>
 

  )
}

export default BaseLayout;