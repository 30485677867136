import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { Row, Col, Navbar, Container, Nav, Image, NavDropdown } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import './NewLandingFramework.css'
import FooterMenu from '../../components/functionalComponents/DashboardLayout/FooterMenuAccess';
import Logo from '../../assets/img/../../assets/img/AVAP_ID_horizontal.png';
import icon_name from '../../assets/img/newDashboard/icon_name.png';
import icono_Infinito from '../../assets/img/newDashboard/icono-Infinito.png';
import logo_communities from '../../assets/img/newDashboard/logo_communities.png';
import logo_alexandria from '../../assets/img/newDashboard/logo-alexandria.png';
import logo_Connect from '../../assets/img/newDashboard/logo-Connect.png';
import Logo101Obex from '../../assets/img/newDashboard/icono_Framework.png';
import logo_framework from '../../assets/img/avap_logo.png'

import logo_framework_producto from '../../assets/img/AVAP_Framework_productoServicio.png'

import icon1 from '../../assets/img/icons/Marketplace_01.svg'
import icon2 from '../../assets/img/icons/Marketplace_02.svg'
import icon3 from '../../assets/img/icons/Marketplace_03.svg'

import logo_framework_titulo from '../../assets/img/AVAP_Marketplace.png'
import cabecera from '../../assets/img/foto_marketplace.png'
import cabeceraMobile from '../../assets/img/foto_marketplace_mobile.png'
import cabecera2 from '../../assets/img/2cabecera.png'

import FooterMenuAccess from '../../components/functionalComponents/DashboardLayout/FooterMenu';
import FooterMenuAccessMobile from '../../components/functionalComponents/DashboardLayout/FooterMenuMobile';
import MobileNavbar from '../../components/functionalComponents/baseLayout/mobileNavbar';
import BrowserNavbar from '../../components/functionalComponents/baseLayout/browserNavbar';

import botonAvapActivo from '../../assets/img/boton_avap_activo.png'
import botonCloudActivo from '../../assets/img/boton_cloud_activo.png'
import botonDsActivo from '../../assets/img/boton_ds_activo.png'
import botonCnpActivo from '../../assets/img/boton_cn_activo.png'
import botonGActivo from '../../assets/img/boton_g_activo.png'
import botonVspActivo from '../../assets/img/boton_vs_activo.png'

import botonAvap from '../../assets/img/boton_avap.png'
import botonCloud from '../../assets/img/boton_cloud.png'
import botonDs from '../../assets/img/boton_ds.png'
import botonCnp from '../../assets/img/boton_cn.png'
import botonG from '../../assets/img/boton_g.png'
import botonVs from '../../assets/img/boton_vs.png'

import AVAPLanguage from '../../assets/img/AVAPLenguaje.png';
import AVAPVirtualServer from '../../assets/img/AVAPVS.png';
import AVAPGateway from '../../assets/img/AVAPGateway.png';
import AVAPDS from '../../assets/img/AVAPDS.png';
import AVAPCloudLogo from '../../assets/img/AVAPCloud.png';
import AVAPCollaborative from '../../assets/img/AVAPCollaborativeN.png'

import Virtualizacion from '../../assets/img/foto_virtualizacion.png'

import productividad from '../../assets/img/productividad.png'
import rentabilidad from '../../assets/img/rentabilidad.png'
import focoproyectos from '../../assets/img/focoproyectos.png'
import tiemposlanzamiento from '../../assets/img/tiemposlanzamiento.png'

import collaborative2 from '../../assets/img/collaborative2.png'
import favicon from '../../assets/img/Favicon_AVAP_Framework.png'
import icon_collaborative from '../../assets/img/iconCollaborative.png'
import connect from '../../assets/img/connect.png'
import avapconect from '../../assets/img/AVAPConnect.png'
import ReactGA from 'react-ga4';
import config from '../../configs/appConfig.json'
import vineta_CN from '../../assets/img/vineta_avap.png'
import gafico_cn_i from '../../assets/img/grafico_avap_i.png'

import { BrowserView, MobileView } from 'react-device-detect';
import TopMenuBar from './TopMenuBar';

type DashProps = {
    sider: any
};



const NewLandingCloud = () => {
    let { path, url } = useRouteMatch();

    const history = useHistory();

    const [avapBut, setavapBut] = useState(true);
    const [vsBut, setvsBut] = useState(false);
    const [gBut, setgBut] = useState(false);
    const [dsBut, setdsBut] = useState(false);
    const [cloudBut, setcloudBut] = useState(false);
    const [CnBut, setcnBut] = useState(false);

    const [menu1, setmenu1] = useState(false);
    const [menu2, setmenu2] = useState(false);
    const [menu3, setmenu3] = useState(false);
    const [menu4, setmenu4] = useState(false);
    const [menu5, setmenu5] = useState(false);


    const cleanAll = () => {
        setavapBut(false);
        setvsBut(false);
        setgBut(false);
        setdsBut(false);
        setcloudBut(false);
        setcnBut(false);
    }

    const cleanMenuAll = () => {
        setmenu1(false);
        setmenu2(false);
        setmenu3(false);
        setmenu4(false);
        setmenu5(false);
    }

    useEffect(() => {
        // favicon
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = favicon; // Usa la ruta importada directamente
        document.head.appendChild(link);
    
        // favicon
        return () => {
          document.head.removeChild(link);
        };
      }, []);


    useEffect(() => {
        window.scrollTo(0, 0);
        //ReactGA.initialize('G-MQZQJN7TQL');
        if (config.url != 'https://avs-primary-pre.101obex.mooo.com') {
            ReactGA.initialize('G-MQZQJN7TQL');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Framework' });
        }

    }, []);


    const handleSignInClick = () => {
        // Aquí puedes realizar cualquier acción antes de redireccionar, si es necesario

        // Redirecciona a otra vista (por ejemplo, '/dashboard')
        history.push('/login');
    };

    const navbarRef = useRef(null);

    const handleClick = () => {
      // Llama a la función cleanMenuAll del componente BrowserNavbar
      if (navbarRef.current) {
        navbarRef.current.cleanMenuAll(); // O también navbarRef.current.cleanAll();
      }
    };




    return (
        <div>
            <BrowserView>
                <div className='d-flex flex-column' style={{ backgroundColor: '#ffffff' }}>

                  <BrowserNavbar  ref={navbarRef}/>



                    <div onClick={() => { handleClick() }}>

                        <div onClick={() => { handleClick() }} className="d-flex flex-column align-items-center">


                            <div className='d-flex flex-column align-items-center'>

                                <Image src={cabecera} style={{ marginTop: '40px' }} width="" alt="Logo" />
                                <Image className='reslogo' src={logo_framework_titulo} alt="Logo" />

                                {/* <div
        className='landingFont0'
        style={{
            marginTop:'80px', 
            position:'relative', 
            color:'black',
            lineHeight:'50px'
        }}                
    >AVAP Connectors</div> */}


                                <div
                                    className='landingFont0'
                                    style={{
                                        marginTop: '0px',
                                        position: 'relative',
                                        color: 'black',
                                        lineHeight: '50px'
                                    }}
                                ><span className='textBold'></span><span className='textBold'></span></div>
                                <div className='landingFont0' style={{ color: 'black' }}></div>
                                <div
                                    className='txt-28-responsive dynContainer-25'
                                    style={{
                                        marginTop: '30px',
                                        position: 'relative',
                                        color: 'black',
                                        textAlign: 'center'
                                    }}
                                >
                                    AVAP Marketplace is a dynamic place that showcases a wide range of API products, connectors, and other tools at a functional level.
                                    It provides developers with a hub to discover, access, and integrate solutions that enhance their API-driven applications.
                                    In addition to the available products, users can contribute their own creations, adding value to the ecosystem and enabling seamless collaboration among developers.
                                    Whether you’re looking for pre-configured API products or custom connectors, AVAP Marketplace is the go-to destination for expanding your development capabilities.

                                    <span className='mt-5 textBold d-block'>¡Coming soon!</span>

                                </div>

                            </div>
                        </div>

                        <div style={{
                            backgroundColor: '#ffffff',
                            height: '200px',
                            textAlign: 'center',
                            paddingTop: '30px'
                        }}>
                            {/*}
    <div className='landingFont2 textBold'>
    Ready to transform API development?
    </div>
    <div className='landingFont3b textBold'>
    Register at AVAP ID
    </div>

*/}
                            <button
                                onClick={() => { history.push('/Register') }}
                                className='greenbutton mt-6 mb-8 m-auto hide-sm d-md-table'
                                style={{ marginTop: '20px' }}
                            >
                                Create my AVAP ID
                            </button>

                        </div>




                        <section className='w-100 bg-division mt-5'>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className='text-center mt-7 mb-4 txt-60 txt-light'>Top <span className=' textBold'>Benefits</span></p>
                                    </div>
                                    <div className="col-md-12">
                                        <p className='text-center txt-20'>Here are the top three benefits of using AVAP Marketplace:</p>
                                    </div>
                                </div>
                                <div className="row mt-6">
                                    <div className="col-md-3">
                                        <Image className='dataIcon' src={icon1} alt="icono" />
                                    </div>
                                    <div className="col-md-9">
                                        <p className='txt-28 textBold mb-0 text-center-sm'>Diverse Product Selection</p>
                                        <p className='txt- text-center-sm'>The access to various platforms, systems, and databases, allow developers to seamlessly connect and exchange data without the need for complex custom configurations, saving time and reducing errors.</p>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-3">
                                        <Image className='dataIcon' src={icon2} alt="icono" />
                                    </div>
                                    <div className="col-md-9">
                                        <p className='txt-28 textBold mb-0 text-center-sm'>Community-Driven Innovation</p>
                                        <p className='txt-20 text-center-sm'>
                                        Not only can you access a curated selection of products, but you can also contribute your own. 
                                        This fosters a community of collaboration and innovation, where developers share and enhance tools to benefit others.

                                        </p>
                                    </div>
                                </div>
                                <div className="row mt-3 pb-7">
                                    <div className="col-md-3">
                                        <Image className='dataIcon' src={icon3} alt="icono" />
                                    </div>
                                    <div className="col-md-9">
                                        <p className='txt-28 textBold mb-0 text-center-sm'>Simplified Integration</p>
                                        <p className='txt-20 text-center-sm '>
                                        All products and connectors in the marketplace are designed for easy integration, helping developers reduce complexity, 
                                        save time, and focus on optimizing their applications without the hassle of extensive configurations.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>




                        <div>
                            <FooterMenuAccess green={true} />
                        </div>

                    </div>
                </div>

            </BrowserView>

            <MobileView>
            <MobileNavbar />


                {/* <Disclaimer /> */}

                <div id='mobileCloud' className="wrapper" >

                    <header className='w-100 headbanner mt-5' style={{ backgroundImage: `url(${cabeceraMobile})`, }} >
                    </header>
                    <section id='intro' className='container'>
                        <div className="row">
                            <div className="col-md-12">
                                <Image src={logo_framework_titulo} className='reslogo' alt="Logo" />
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-md-12">
                                <h1 className='text-center hero-title'>
                                    Your account for AVAP Framework. Your AVAP ID will give you
                                    <span className='textBold'>
                                        &nbsp;access to AVAP products and services.
                                    </span>
                                </h1>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-md-12">
                                <p className='bodyText text-center mb-5 mt-2'>
                                AVAP Marketplace is a dynamic place that showcases a wide range of API products, connectors, and other tools at a functional level.
                                 It provides developers with a hub to discover, access, and integrate solutions that enhance their API-driven applications. In addition to the available products, users can contribute their own creations, 
                                adding value to the ecosystem and enabling seamless collaboration among developers. 
                                Whether you’re looking for pre-configured API products or custom connectors, AVAP Marketplace is the go-to destination for expanding your development capabilities.

                                <span className='mt-5 textBold d-block'>¡Coming soon!</span>
                                </p>
                                {/*}  <button onClick={() => { history.push('/Register') }} className='bluebutton  d-table m-auto mb-6 '> Try it</button> */}
                            </div>
                        </div>

                    </section>

                    <section className='w-100 bg-division'>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='text-center hero-title mt-6'>Top <span className='textBold'>Benefits</span></p>
                                </div>
                                <div className="col-md-12">
                                    <p className='text-center txt-20'>Here are the top three benefits of using AVAP Marketplace:</p>
                                </div>
                            </div>
                            <div className="row mt-6">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={icon1} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 text-center'>Diverse Product Selection</p>
                                    <p className='txt-20 text-center'>
                                    Explore a wide variety of API products and connectors that cater to various development needs. 
                                    Whether you’re looking for pre-built tools or user-contributed solutions, the AVAP Marketplace has something to accelerate your projects.
                                    </p>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={icon2} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 text-center'>Community-Driven Innovation</p>
                                    <p className='txt-20 text-center'>
                                    Not only can you access a curated selection of products, but you can also contribute your own. This fosters a community of collaboration and innovation, where developers share and enhance tools to benefit others.
                                    </p>
                                </div>
                            </div>
                            <div className="row mt-3 pb-7">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={icon3} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 text-center'>Simplified Integration</p>
                                    <p className='txt-20 text-center'>
                                    All products and connectors in the marketplace are designed for easy integration, helping developers reduce complexity, save time, and focus on optimizing their applications without the hassle of extensive configurations.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>




                </div>


                <div>
                    <FooterMenuAccessMobile green={true} />
                </div>




            </MobileView>

        </div>
    )
}

export default NewLandingCloud;