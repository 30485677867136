import React, { FunctionComponent, useState, useEffect } from 'react'; 
import { Row, Col, Card } from 'react-bootstrap';
import { parseToCoin } from '../../services/AmountService';


type ProductDetailProps = {
    title: string,
    priceMonthly: number,
    priceYear: number,
    descrip: string,
    annualPayment: boolean,
    letMonths: number,
    isBlue?: boolean,
    spanish: boolean

}

const ProductDetailCard:FunctionComponent<ProductDetailProps> = (props: ProductDetailProps) => {

  const { title, priceMonthly, priceYear, descrip, annualPayment, letMonths, spanish } = props;

  const [ priceProduct, setPriceProduct ] = useState(0);
  const  [onetime, setOnetime] = useState(false);

  useEffect(() => {
    if (priceMonthly==0) {
      setOnetime(true);
      setPriceProduct(priceYear)
    } else {
    if(annualPayment){
      const priceCalc = priceYear * letMonths;
      setPriceProduct(priceCalc);
    }else if(!annualPayment){
      setPriceProduct(priceMonthly);
    }
    }
  }, [annualPayment])

  return(
    <Card className="product-detail-card mb-3">
      <Card.Body className="p-3">
        <Card.Title>
          <Row>
            <Col><span className={`title-product titlesPaymentConfirmation ${props.isBlue?"blue":"green"}`}>{title}</span></Col>
          </Row>
        </Card.Title>
        <div className="border-top my-2"></div>
        <Card.Text>
          <Row>
            <Col md="6">
              <span>
                {descrip}
              </span>
            </Col>
            <Col md="6" className="text-right my-auto">
            {onetime ?
              <span><span className="product-price">{parseToCoin(priceProduct)}</span>/one time</span>
              : <span><span className="product-price">{parseToCoin(priceProduct)}</span>{( spanish ? annualPayment ? '/año' : '/mes' : annualPayment ? '/year' : '/month')}</span>
              }
            </Col>
          </Row>
        </Card.Text>
      </Card.Body>
    </Card>
    );
}

export default ProductDetailCard;