import React, { FunctionComponent, useState, useEffect } from 'react';
import { Modal, Nav, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import icono_ubicacion from '../../../assets/img/icono_ubicacion.png';
import icono_mail from '../../../assets/img/icono-mail.png';
//import iconos_familia from '../../../assets/img/iconos_familia.png';
import iconos_familia from '../../../assets/img/LogosFamilia_pie.png';
import avap_logo from '../../../assets/img/avap_logo.png'

import twitterblue from '../../../assets/img/twittergrey.png'
import linkedinblue from '../../../assets/img/linkedingrey.png'

import yticon from '../../../assets/img/icon-yt.png'
import redicon from '../../../assets/img/icon-redd.png'
import fbicon from '../../../assets/img/icono_fb.png'


import './style.css'
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLocationArrow, faMapMarkedAlt, faMapMarker, faMapMarkerAlt, faMapPin } from '@fortawesome/free-solid-svg-icons';
import { faPinterest } from '@fortawesome/free-brands-svg-icons';

type color = {
  green?: boolean,
}


const FooterMenu: FunctionComponent<color> = props => {
  const [neswleterEmail, setneswleterEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [registerin, setRegistering] = useState(false);
  const [showModal2, setshowModal2] = useState(false);
  
  const handleClose = () => {
    setshowModal2(false);
  }
  const history = useHistory(); 

  const validateNewsLetter = (email) => {
    const emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (email.match(emailFormat)) setValidEmail(true); else setValidEmail(false);

  }
  const porcessNewsLetterSuscription = async () => {
    console.log(neswleterEmail);
    const payload = {}
    setRegistering(true);
    setValidEmail(false);
    try {
    await ObexRequestHandler.post(`/salesforce/register_neswletter?email=${neswleterEmail}`, payload);
    setRegistering(false);
    setValidEmail(false);
    setneswleterEmail('');  
    setshowModal2(true);
  } catch {
      setRegistering(false);
      setValidEmail(true);
    }

  };


  useEffect(() => {
    console.log(window.location.pathname);
  }, []);

  return (
    // <Nav id="footer-dashboard" className="justify-content-md-center ">
    //   <Nav.Item>
    //     <Nav.Link 
    //       target="_blank" 
    //       rel="noopener noreferrer" 
    //       href="https://101obex.com/termsconditions">Terms and Conditions1</Nav.Link>
    //   </Nav.Item>
    //   <Nav.Item>
    //     <Nav.Link 
    //       target="_blank" rel="noopener noreferrer" 
    //       href="https://101obex.com/privacypolicy">Privacy Policy</Nav.Link>
    //   </Nav.Item>
    // </Nav>
    <div id="footer-dashboard" className='pt-5' style={{ position: 'relative', }}>


      <div className='container'>

        <div className="row">

          <div onClick={() => { window.open('/') }} className='col-lg-5 col-md-12 mb-4' style={{ textAlign: 'center', cursor: 'pointer', }}>
            {/*<div className='pb-1'>
                <img src={avap_logo} alt="" width="271px" className='sizeLogo' />
              </div>*/}
            <div>
              <img src={iconos_familia} width="271px" alt="" />
            </div>

          </div>


          <div className="col-lg-7 col-md-12" style={{ marginTop: '2em' }}>
            <div className="mailingContainer" style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
              <div>
                <input value={neswleterEmail}
                  disabled={registerin}
                  onChange={
                    (event) => {
                      setneswleterEmail(event.target.value);
                      validateNewsLetter(event.target.value)
                    }
                  }
                  className='form-control'
                  style={{
                    borderColor: `${!props.green || window.location.pathname === '/framework'
                      || window.location.pathname === '/about'
                      || window.location.pathname === '/ai'
                      || window.location.pathname === '/api-publisher'
                      || window.location.pathname === '/avap'
                      || window.location.pathname === '/avs'
                      || window.location.pathname === '/cloud'
                      || window.location.pathname === '/avap-connectors'
                      || window.location.pathname === '/avapid'
                      || window.location.pathname === '/vsc-extensions'
                      || window.location.pathname === '/avap-marketplace'
                      || window.location.pathname === '/version-control'
                      || window.location.pathname === '/devstudio'


                      ? '#147bce' : '#316952'}`,
                    borderRadius: '12px',
                    borderWidth: '2px'
                  }}>

                </input>
              </div>
              <div>
                <button
                  onClick={
                    () => {
                      porcessNewsLetterSuscription()
                    }
                  }
                  disabled={!validEmail}
                  className={`${!props.green || window.location.pathname === '/framework'


                    || window.location.pathname === '/devstudio'
                    || window.location.pathname === '/about'
                    || window.location.pathname === '/ai'
                    || window.location.pathname === '/api-publisher'
                    || window.location.pathname === '/avap'
                    || window.location.pathname === '/avs'
                    || window.location.pathname === '/cloud'
                    || window.location.pathname === '/avap-connectors'
                    || window.location.pathname === '/avapid'
                    || window.location.pathname === '/vsc-extensions'
                    || window.location.pathname === '/avap-marketplace'
                    || window.location.pathname === '/version-control'


                    ? 'custom-button-blue' : 'custom-button-green'} textBold`}
                  style={{ paddingLeft: '10px', paddingRight: '10px' }}
                    >
                  {registerin ? <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} /> : 'Subscribe to newsletter'}
                </button>
              </div>
            </div>
          </div>



        </div>


      </div>





      <div className='container mt-5'>
        <div className='row row-cols-lg-5 row-cols-md-4 row-cols-sm-1 row-cols-1' style={{ textAlign: 'center' }}>
          <div className='col mb-3'>
            <div className='footerTitle'>Products & Services</div>
            <div style={{ cursor: 'pointer' }} onClick={() => { history.push('avap') }} className='footerLink'>
              AVAP (Advance Virtual API Programming)
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => { history.push('/vsc-extensions') }} className='footerLink'>
              AVAP API Extensions for Microsoft Visual Code
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => { history.push('devstudio') }} className='footerLink'>
              AVAP Dev Studio
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => { history.push('version-control') }} className='footerLink'>
              AVAP API Version Control
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => { history.push('api-publisher') }} className='footerLink'>
              AVAP API Publisher
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => { history.push('avap-connectors') }} className='footerLink'>
              AVAP Connectors
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => { history.push('avs') }} className='footerLink'>
              AVAP Virtual Server
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => { history.push('gateway') }} className='footerLink'>
              AVAP Gateway
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => { history.push('cloud') }} className='footerLink'>
              AVAP Cloud
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => { history.push('collaborative') }} className='footerLink'>
              AVAP Collaborative Network
            </div>
            {/*}
                <div className='d-flex align-items-baseline' >
                  <div className='text2 mt-1'>sales@101obex.com</div>
                </div>

                <div className='d-flex mr-1 marginTopimg' style={{marginTop:'10px'}}>
                    <img src={linkedinblue} alt="" width={"16px"} height={"16px"} />
                    <img style={{marginLeft:'5px'}} src={twitterblue} alt="" width={"16px"} height={"16px"} />
                  </div>
*/}
          </div>
          <div className='col mb-3'>
            <div className='footerTitle'>AI Brunix</div>
            <div style={{ cursor: 'pointer' }} onClick={() => { history.push('/ai') }} className='footerLink'>
              AI Brunix
            </div>
            <div className='footerTitle'>AVAP ID</div>
            <div style={{ cursor: 'pointer' }} onClick={() => { history.push('/avapid') }} className='footerLink'>
              AVAP ID
            </div>
            {/*}
                <div className='footerLink'>
                  Executive Leadership
                </div>
                <div className='footerLink'>
                  News & Stories
                </div>
                <div className='footerLink'>
                  Invertor Relations
                </div>
                <div className='footerLink'>
                  Customer Stories
                </div>
                <div className='footerLink'>
                  Diversity, Equity, Inclusion
                </div>
                <div className='footerLink'>
                  Enviroment, Social & Governance
                </div>
                <div className='footerLink'>
                  AI at VMware
                </div>
*/}


          </div>
          <div className='col mb-3'>
            <div className='footerTitle'>Resources</div>
            {/* <div style={{ cursor: 'pointer' }} onClick={() => { window.open('/default', '_blank') }} className='footerLink'>
              AVAP Developer Portal
            </div> */}
            <div style={{ cursor: 'pointer' }} onClick={() => { history.push('/avap-marketplace') }} className='footerLink'>
              AVAP Marketplace
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/?language=en_US', '_blank') }} className='footerLink'>
              AVAP Alexandria
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => { window.open('https://speed-computing-1656.my.site.com/communities/s/', '_blank') }} className='footerLink'>
              AVAP Communities
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => { window.open('http://blog.avapframework.com', '_blank') }} className='footerLink'>
              AVAP Blog
            </div>
            {/*}
                <div className='footerLink'>
                  Office Locations
                </div>
                <div className='footerLink'>
                  VMware Cloud Trust Center
                </div>
                <div className='footerLink'>
                  COVID-19 Resources
                </div>

*/}


          </div>
          <div className='col mb-3'>
            <div className='footerTitle'>Company</div>
            <div style={{ cursor: 'pointer' }} onClick={() => { window.open('https://www.101obex.com', '_blank') }} className='footerLink'>
              About us
            </div>
            {/*}
                <div className='footerLink'>
                  Support Policies
                </div>
                <div className='footerLink'>
                  Product Documentation
                </div>
                <div className='footerLink'>
                  Compatibility Guide
                </div>
                <div className='footerLink'>
                  Terms & Conditions
                </div>
                <div className='footerLink'>
                  California Trnasparency Act Statement
                </div>
                <div className='footerLink'>
                  Hands-on Labs
                </div>
*/}

          </div>
          <div className='col mb-3'>
            <div className='footerTitle'>Legal</div>
            <div className='footerLink' style={{ cursor: 'pointer' }}
              onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/news/avap-collaborative-network-terms-conditions-MCXYCPQ5L53JDSFN4HXYI66J2764?language=en_US') }}
            >

              Terms & Conditions (AVAP Collaborative Networks)
            </div>
            <div className='footerLink' style={{ cursor: 'pointer' }}
              onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/news/avap-cloud-professional-business-terms-conditions-MCOYQYTA5VQNDZDAS5UGXSU3GEV4?language=en_US') }}
            >
              Terms & Conditions (AVAP Cloud)
            </div>
            <div style={{ cursor: 'pointer' }}
              onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/news/privacy-policy-MCSYZARSDENBDAVITQIIO6OWFYLI?language=en_US') }}
              className='footerLink'>
              Privacy Policy
            </div>
            {/*}
                <div className='footerLink'>
                  Support Policies
                </div>
                <div className='footerLink'>
                  Product Documentation
                </div>
                <div className='footerLink'>
                  Compatibility Guide
                </div>
                <div className='footerLink'>
                  Terms & Conditions
                </div>
                <div className='footerLink'>
                  California Trnasparency Act Statement
                </div>
                <div className='footerLink'>
                  Hands-on Labs
                </div>
*/}

          </div>
        </div>
      </div>


      <div className="w-100" style={{ backgroundColor: '#E2E2E2', paddingTop: '2em' }}>
        <div className='container mt-3' >
        <div className="row justify-content-center">
      {/*    <div className="col" style={{marginLeft:'-15px'}}> */}
            <div className="d-flex float-right flex-wrap" style={{ maxWidth: '365px' }}>
              <div className='text-boldLogin w-100' style={{ textAlign: 'center' }}>Corporate Head Office</div>
              <div className='d-flex' style={{ marginLeft: '4em', marginRight: '4em', textAlign: 'center' }}>
                <div className='d-flexmarginTopimg' style={{ textAlign: 'center' }}>
                {/*}  <img style={{ marginBottom: '3px', marginTop: 'auto', marginRight: '0px' }} src={icono_ubicacion} alt="" />*/}
                </div>
                
                <div className='text-center text2'><FontAwesomeIcon style={{ width: '10px', paddingTop: '10px' }} icon={faMapMarkerAlt} size="2x" className="mr-1" />101OBEX, CORP 440 N Wolfe Rd 94085 Sunnyvale, CA</div>
              </div>
            </div>

     {/*}     </div> */}


        {/*}  <div className="col"> {*/}
            <div className="">
             {/*} <div style={{ width: '2px', height: '48px', backgroundColor: '#ebebeb', marginRight: '10px', paddingTop: '10px' }}></div> */}
              
                <div className='d-flex align-items-baseline' >
                <div className=' ' style={{ width: '100%' }}>
                   {/*} <img src={icono_mail} alt="correo"  style={{ marginRight: '5px' }} /> */}
                  <FontAwesomeIcon style={{ width: '12px', paddingTop: '14px' }} icon={faEnvelope} size="2x" />
                  </div>
                  <div className='text2 mt-1'>sales@101obex.com</div>
                </div>

              <div style={{ display: 'flex', marginTop: '15px' }}>
                  <img onClick={() => { window.open('https://www.linkedin.com/company/avap-framework', '_blank') }} style={{ cursor: 'pointer' }} src={linkedinblue} alt="" width={"16px"} height={"16px"} />
                  <img onClick={() => { window.open('https://x.com/AvapFramework', '_blank') }} style={{ cursor: 'pointer', marginLeft: '20px' }} src={twitterblue} alt="" width={"16px"} height={"16px"} />
                  <img onClick={() => { window.open('https://www.youtube.com/@AVAPFramework', '_blank') }} src={yticon} alt="" style={{ cursor: 'pointer', marginLeft: '20px' }} width={'16px'} height={'16px'} />
                  <img onClick={() => { window.open('https://www.reddit.com/user/AVAP_Framework/', '_blank') }} style={{ cursor: 'pointer', marginLeft: '20px' }} src={redicon} alt="" width={'16px'} height={'16px'} />
                <img onClick={() => { window.open('https://www.facebook.com/AVAPFramework') }} style={{ cursor: 'pointer', marginLeft: '20px' }} src={fbicon} alt="" width={'16px'} height={'16px'} />
                </div>
            
            </div>

      {/*}    </div> */}
        </div>
        </div>
      </div>



      <div style={{ width: '100%', height: '2em', backgroundColor: '#E2E2E2', marginRight: 'auto' }}></div>

      <div style={{ width: '100%', height: '1px', backgroundColor: '#ebebeb', marginRight: 'auto' }}></div>

      <div className='pt-4 p-3-5' style={{ textAlign: 'center', backgroundColor: '#E2E2E2' }}>
        © 2024 101OBEX, CORP, All rights reserved

      </div>


      <>
        <Modal style={{ marginTop: '7rem' }} show={showModal2} onHide={handleClose}>
          <Modal.Body className='colorBlack' style={{ textAlign: 'center' }}>
            <div><h1>Congratulations</h1></div>
            <div>You have been registered in the AVAP Newsletter</div>
          </Modal.Body>
          <Modal.Footer>

            <button className="btn custom-button  textBold" onClick={handleClose}>
              Ok
            </button>

          </Modal.Footer>
        </Modal>
      </>




    </div>




  );
}


export default FooterMenu;