import React, { FunctionComponent } from 'react';
import { Row, Col, Form, Button, Spinner, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

type buttonSubmitProps = {
  submitDisabled: boolean,
  success: any,
  loading: boolean,
  error: string,
  textButton: string,
  align?: string,
  color?: boolean
  blue?:boolean
  handleSubmit: (event) => void
}

const ButtonSubmit:FunctionComponent<buttonSubmitProps> = (props) => {

  const { submitDisabled, success, loading, error, align, textButton, color,blue, handleSubmit } = props;
  var colorBoton = color ? "custom-button-orange textBold w-100":(blue ? "custom-button-blue textBold  w-100": "custom-button-green textBold w-100");
  return (
    <Form.Row className="my-3">
      <Col md="5" style={{padding:'0px'} }className={align == 'left' && 'mr-auto' || align == 'right' && 'ml-auto' || 'mx-auto'}>
        <button disabled={submitDisabled || loading} onClick={handleSubmit} type="submit" className={colorBoton}>
          {textButton} {success && <FontAwesomeIcon icon={faCheck}/>}
          {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>}
        </button>
      </Col>
    </Form.Row>
  )
}

export default ButtonSubmit;