import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { Row, Col, Navbar, Container, Nav, Image, NavDropdown } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import './NewLandingFramework.css'
import FooterMenu from '../../components/functionalComponents/DashboardLayout/FooterMenuAccess';
import Logo from '../../assets/img/../../assets/img/AVAP_ID_horizontal.png';
import icon_name from '../../assets/img/newDashboard/icon_name.png';
import icono_Infinito from '../../assets/img/newDashboard/icono-Infinito.png';
import logo_communities from '../../assets/img/newDashboard/logo_communities.png';
import logo_alexandria from '../../assets/img/newDashboard/logo-alexandria.png';
import logo_Connect from '../../assets/img/newDashboard/logo-Connect.png';
import Logo101Obex from '../../assets/img/newDashboard/icono_Framework.png';
import logo_framework from '../../assets/img/avap_logo.png'
import favicon from '../../assets/img/Favicon_AVAP_Framework.png'

import logo_framework_producto from '../../assets/img/AVAP_Framework_productoServicio.png'

import lenguaje from '../../assets/img/lenguaje.png'
import virtualidad from '../../assets/img/virtualidad.png'
import inteligencia from '../../assets/img/inteligencia.png'

import cn_01 from '../../assets/img/icons/CollaborativeNetwork_01.svg'
import cn_02 from '../../assets/img/icons/CollaborativeNetwork_02.svg'
import cn_03 from '../../assets/img/icons/CollaborativeNetwork_03.svg'
import cn_04 from '../../assets/img/icons/CollaborativeNetwork_04.svg'
import cn_05 from '../../assets/img/icons/CollaborativeNetwork_05.svg'


import vineta_CN from '../../assets/img/vineta_CN.png'


import gafico_cn_i from '../../assets/img/grafico_CN_I.png'

import logo_framework_titulo from '../../assets/img/AVAP_CN_P.png'
import cabecera from '../../assets/img/foto_CN.png'
import cabecera2 from '../../assets/img/2cabecera.png'
import FooterMenuAccess from '../../components/functionalComponents/DashboardLayout/FooterMenu';
import FooterMenuAccessMobile from '../../components/functionalComponents/DashboardLayout/FooterMenuMobile';
import MobileNabvar from '../../components/functionalComponents/baseLayout/mobileNavbar';
import BrowserNavbar from '../../components/functionalComponents/baseLayout/browserNavbar';
import botonAvapActivo from '../../assets/img/boton_avap_activo.png'
import botonCloudActivo from '../../assets/img/boton_cloud_activo.png'
import botonDsActivo from '../../assets/img/boton_ds_activo.png'
import botonCnpActivo from '../../assets/img/boton_cn_activo.png'
import botonGActivo from '../../assets/img/boton_g_activo.png'
import botonVspActivo from '../../assets/img/boton_vs_activo.png'

import botonAvap from '../../assets/img/boton_avap.png'
import botonCloud from '../../assets/img/boton_cloud.png'
import botonDs from '../../assets/img/boton_ds.png'
import botonCnp from '../../assets/img/boton_cn.png'
import botonG from '../../assets/img/boton_g.png'
import botonVs from '../../assets/img/boton_vs.png'

import AVAPLanguage from '../../assets/img/AVAPLenguaje.png';
import AVAPVirtualServer from '../../assets/img/AVAPVS.png';
import AVAPGateway from '../../assets/img/AVAPGateway.png';
import AVAPDS from '../../assets/img/AVAPDS.png';
import AVAPCloudLogo from '../../assets/img/AVAPCloud.png';
import AVAPCollaborative from '../../assets/img/AVAPCollaborativeN.png'

import Virtualizacion from '../../assets/img/foto_virtualizacion.png'

import productividad from '../../assets/img/productividad.png'
import rentabilidad from '../../assets/img/rentabilidad.png'
import focoproyectos from '../../assets/img/focoproyectos.png'
import tiemposlanzamiento from '../../assets/img/tiemposlanzamiento.png'

import collaborative2 from '../../assets/img/collaborative2.png'

import icon_collaborative from '../../assets/img/iconCollaborative.png'
import connect from '../../assets/img/connect.png'
import avapconect from '../../assets/img/AVAPConnect.png'
import ReactGA from 'react-ga4';
import config from '../../configs/appConfig.json'
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../../components/mobileComponents/DisclaimerCloud';
import TopMenuBar from './TopMenuBar';

type DashProps = {
    sider: any
};



const NewLandingCollaborative = () => {
    let { path, url } = useRouteMatch();

    const history = useHistory();

    const [avapBut, setavapBut] = useState(true);
    const [vsBut, setvsBut] = useState(false);
    const [gBut, setgBut] = useState(false);
    const [dsBut, setdsBut] = useState(false);
    const [cloudBut, setcloudBut] = useState(false);
    const [CnBut, setcnBut] = useState(false);

    const [menu1, setmenu1] = useState(false);
    const [menu2, setmenu2] = useState(false);
    const [menu3, setmenu3] = useState(false);
    const [menu4, setmenu4] = useState(false);
    const [menu5, setmenu5] = useState(false);


    const cleanAll = () => {
        setavapBut(false);
        setvsBut(false);
        setgBut(false);
        setdsBut(false);
        setcloudBut(false);
        setcnBut(false);
    }

    const cleanMenuAll = () => {
        setmenu1(false);
        setmenu2(false);
        setmenu3(false);
        setmenu4(false);
        setmenu5(false);
    }


    useEffect(() => {
        // favicon
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = favicon; // Usa la ruta importada directamente
        document.head.appendChild(link);

        // favicon
        return () => {
            document.head.removeChild(link);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        //ReactGA.initialize('G-MQZQJN7TQL');
        if (config.url != 'https://avs-primary-pre.101obex.mooo.com') {
            ReactGA.initialize('G-MQZQJN7TQL');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Framework' });
        }

    }, []);


    const handleSignInClick = () => {
        // Aquí puedes realizar cualquier acción antes de redireccionar, si es necesario

        // Redirecciona a otra vista (por ejemplo, '/dashboard')
        history.push('/login');
    };

    const navbarRef = useRef(null);

    const handleClick = () => {
        // Llama a la función cleanMenuAll del componente BrowserNavbar
        if (navbarRef.current) {
            navbarRef.current.cleanMenuAll(); // O también navbarRef.current.cleanAll();
        }
    };




    return (
        <>
            <BrowserView>
            <BrowserNavbar  ref={navbarRef}/>

                <div id='mobileCollab' className="wrapper"  onClick={() => { handleClick() }}>

                    <header className='w-100 headbanner' style={{ backgroundImage: `url(${cabecera})`, }} >
                    </header>
                    <section id='intro' className='container'>
                        <div className="row">
                            <div className="col-md-12">
                                <Image src={logo_framework_titulo} className='reslogo' alt="Logo" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className='text-center hero-title'>
                                    Unlock your full potential with de 1st <br className='dynamicHide-inverse' />
                                    free <span className='textBold'>peer-to-peer</span> network for API
                                    development and deployment
                                </h1>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-12">
                                <p className='bodyText text-center mb-5'>
                                    A peer-to-peer platform with exponential growth capacity, allowing developers to harness a complete
                                    deployment of AVS (AVAP Virtual Server) and AVAP Interpreter & Server Language for API developmen
                                    t and publication. AVAP Collaborative Network can assess each API request for direct processing
                                    or redirection to another server with lower response time, utilizing dedicated channels for AVAP
                                    Virtual Code transmission to amplify virtual machine capacity across language servers.
                                </p>

                                 <button onClick={() => { history.push('/Register') }} className='greenbutton m-auto mb-6  hide-sm d-md-table'> Create my AVAP ID</button>
                            </div>
                        </div>

                    </section>
                    <section id='items' className='w-100 bg-division mt-5'>
                        <div className="container">
                            <div className="row mb-5 pt-5">
                                <div className="col-md-2">
                                    <Image src={cn_01} className='dataicon' width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold  title'>Free and international network</div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>Open and universal access for anyone wishing to join, without entry barriers. Develop like a pro, invest like a homebrew.</div>

                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image src={cn_02} className='dataicon' width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title '>Exponencial capacity</div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>The network features AVAP and AVS language servers whose capacity scales with the number of active peers. Utilizing its Advanced Gateway as the endpoint for diverse environments, it acts as an optimized proxy to ensure optimal handling of requests by selecting the best AVS based on various call parameters.
                                        Programmed in Lua.</div>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image src={cn_03} className='dataicon' width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>Intelligent routing                            </div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>Distributed execution with performance comparable to local execution, assisted by artificial intelligence.</div>

                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image src={cn_04} className='dataicon' width="" /></div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>Dynamic thread distribution                            </div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>Management of processing threads distributed among active and available peers within the peer-to-peer network at any moment.</div>

                                </div>
                            </div>
                            <div className="row pb-7">
                                <div className="col-md-2">
                                    <Image src={cn_05} className='dataicon' width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>High-level security                           </div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>Robust security measures to protect data integrity and user privacy
                                        within the peer-to-peer network.</div>

                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="beneficts" className='container mt-5 pb-5'>
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className='hero-title text-center'>
                                    Top AVAP Collaborative <br />  Network Benefits
                                </h1>
                            </div>
                            <div className="col-md-12">
                                <p className='title'>AVAP Collaborative Network <br /> Interface</p>
                            </div>
                        </div>
                    </section>

                    <section className='container-fluid responsiveSpace pb-5'>

                    <div className="row">
                            <div className="col-md-6 order-md-2 order-sm-1">
                                <Image src={gafico_cn_i} style={{ width: '100%', padding: "10px" }} width="" />
                            </div>
                            <div className="col-md-6 order-md-1 order-sm-2">
                                <div className='landingFont3b m-auto' style={{ marginTop: '20px', width: '90%', }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Develop, publish, and manage virtual APIs in a professional environment without any additional costs.</div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Facilitate exploration, discovery, and learning of API technology.</div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Publish APIs without the need to purchase cloud services.</div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Access advanced tools for management and professional development.</div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Implement APIs agilely and at scale with shared resources.</div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Continuously update and improve based on community feedback and contributions.</div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Ensure security and control over data and API access management.</div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Access detailed documentation and educational resources to facilitate integration and use of AVAP Framework.</div>
                            </div>
                        </div>

                    </section>




                </div>


                <div>
                    <FooterMenuAccess green={true} />
                </div>




            </BrowserView>
            <MobileView>
                <MobileNabvar />




                {/* <Disclaimer /> */}

                <div id='mobileCollab' className="wrapper" >

                    <header className='w-100 headbanner' style={{ backgroundImage: `url(${cabecera})`, }} >
                    </header>
                    <section id='intro' className='container'>
                        <div className="row">
                            <div className="col-md-12">
                                <Image src={logo_framework_titulo} className='reslogo' alt="Logo" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className='text-center hero-title'>
                                    Unlock your full potential with de 1st
                                    free <span className='textBold'>peer-to-peer</span> network for API
                                    development and deployment
                                </h1>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-12">
                                <p className='bodyText text-center mb-5'>
                                    A peer-to-peer platform with exponential growth capacity, allowing developers to harness a complete
                                    deployment of AVS (AVAP Virtual Server) and AVAP Interpreter & Server Language for API developmen
                                    t and publication. AVAP Collaborative Network can assess each API request for direct processing
                                    or redirection to another server with lower response time, utilizing dedicated channels for AVAP
                                    Virtual Code transmission to amplify virtual machine capacity across language servers.
                                </p>

                                {/*}    <button onClick={() => { history.push('/Register') }} className='tealbutton  d-table m-auto mb-6 '> Try it</button> */}
                            </div>
                        </div>

                    </section>
                    <section id='items' className='w-100 bg-division mt-5'>
                        <div className="container">
                            <div className="row mb-5">
                                <div className="col-md-2 mt-5">
                                    <Image src={cn_01} className='dataicon' width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold  title'>Free and international network</div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>Open and universal access for anyone wishing to join, without entry barriers. Develop like a pro, invest like a homebrew.</div>

                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image src={cn_02} className='dataicon' width=""  />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title '>Exponencial capacity</div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>The network features AVAP and AVS language servers whose capacity scales with the number of active peers. Utilizing its Advanced Gateway as the endpoint for diverse environments, it acts as an optimized proxy to ensure optimal handling of requests by selecting the best AVS based on various call parameters.
                                        Programmed in Lua.</div>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image src={cn_03} className='dataicon' width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>Intelligent routing                            </div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>Distributed execution with performance comparable to local execution, assisted by artificial intelligence.</div>

                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image src={cn_04} className='dataicon' width="" /></div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>Dynamic thread distribution                            </div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>Management of processing threads distributed among active and available peers within the peer-to-peer network at any moment.</div>

                                </div>
                            </div>
                            <div className="row pb-7">
                                <div className="col-md-2">
                                    <Image src={cn_05} className='dataicon' width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>High-level security                           </div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>Robust security measures to protect data integrity and user privacy
                                        within the peer-to-peer network.</div>

                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="beneficts" className='container mt-5 pb-5'>
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className='hero-title text-center'>
                                    Top AVAP Collaborative <br />  Network Benefits
                                </h1>
                            </div>
                            <div className="col-md-12">
                                <p className='title'>AVAP Collaborative Network <br /> Interface</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Image src={gafico_cn_i} style={{ width: '100%', padding: "10px" }} width="" />
                            </div>
                            <div className="col-md-6">
                                <div className='landingFont3b m-auto' style={{ marginTop: '20px', width: '90%', }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Develop, publish, and manage virtual APIs in a professional environment without any additional costs.</div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Facilitate exploration, discovery, and learning of API technology.</div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Publish APIs without the need to purchase cloud services.</div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Access advanced tools for management and professional development.</div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Implement APIs agilely and at scale with shared resources.</div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Continuously update and improve based on community feedback and contributions.</div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Ensure security and control over data and API access management.</div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Access detailed documentation and educational resources to facilitate integration and use of AVAP Framework.</div>
                            </div>
                        </div>
                    </section>


                </div>


                <div>
                    <FooterMenuAccessMobile green={true} />
                </div>




            </MobileView>
        </>
    )
}

export default NewLandingCollaborative;