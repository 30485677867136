import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { Row, Col, Navbar, Container, Nav, Image, NavDropdown } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import './NewLandingFramework.css'
import FooterMenu from '../../components/functionalComponents/DashboardLayout/FooterMenuAccess';
import FooterMenuMobile from '../../components/functionalComponents/DashboardLayout/FooterMenuMobile';
import Logo from '../../assets/img/../../assets/img/AVAP_ID_horizontal.png';
import icon_name from '../../assets/img/newDashboard/icon_name.png';
import icono_Infinito from '../../assets/img/newDashboard/icono-Infinito.png';
import logo_communities from '../../assets/img/newDashboard/logo_communities.png';
import logo_alexandria from '../../assets/img/newDashboard/logo-alexandria.png';
import logo_Connect from '../../assets/img/newDashboard/logo-Connect.png';
import Logo101Obex from '../../assets/img/newDashboard/icono_Framework.png';
import logo_framework from '../../assets/img/avap_logo.png'
import avap_logo from '../../assets/img/avap_logo.png'
import logo_framework_producto from '../../assets/img/AVAP_Framework_productoServicio.png'

import lenguaje from '../../assets/img/lenguaje.png'
import virtualidad from '../../assets/img/virtualidad.png'
import inteligencia from '../../assets/img/inteligencia.png'

import iconos_familia from '../../assets/img/iconos_familia.png';
import logo_framework_titulo from '../../assets/img/twelveHundred_inversionistas@2x.png'
import cabecera from '../../assets/img/textura_cabecera@2x.png'
import cabecera2 from '../../assets/img/logotipo101@2x.png'
import FooterMenuAccess from '../../components/functionalComponents/DashboardLayout/FooterMenu';
import MobileNavbar from '../../components/functionalComponents/baseLayout/mobileNavbar';
import BrowserNavbar from '../../components/functionalComponents/baseLayout/browserNavbar';

import favicon from '../../assets/img/Favicon_AVAP_Framework.png'
import icon_collaborative from '../../assets/img/iconCollaborative.png'
import connect from '../../assets/img/connect.png'
import avapconect from '../../assets/img/AVAPConnect.png'
import ReactGA from 'react-ga4';
import config from '../../configs/appConfig.json'
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../../components/mobileComponents/DisclaimerCloud';

type DashProps = {
    sider: any
};



const NewLandingABOUT = () => {
    let { path, url } = useRouteMatch();

    const history = useHistory();

    const [avapBut, setavapBut] = useState(true);
    const [vsBut, setvsBut] = useState(false);
    const [gBut, setgBut] = useState(false);
    const [dsBut, setdsBut] = useState(false);
    const [cloudBut, setcloudBut] = useState(false);
    const [CnBut, setcnBut] = useState(false);

    const [menu1, setmenu1] = useState(false);
    const [menu2, setmenu2] = useState(false);
    const [menu3, setmenu3] = useState(false);
    const [menu4, setmenu4] = useState(false);
    const [menu5, setmenu5] = useState(false);


    const cleanAll = () => {
        setavapBut(false);
        setvsBut(false);
        setgBut(false);
        setdsBut(false);
        setcloudBut(false);
        setcnBut(false);
    }

    const cleanMenuAll = () => {
        setmenu1(false);
        setmenu2(false);
        setmenu3(false);
        setmenu4(false);
        setmenu5(false);
    }

    useEffect(() => {
        // favicon
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = favicon; // Usa la ruta importada directamente
        document.head.appendChild(link);
    
        // favicon
        return () => {
          document.head.removeChild(link);
        };
      }, []);


    useEffect(() => {
        window.scrollTo(0, 0);
        //ReactGA.initialize('G-MQZQJN7TQL');
        if (config.url != 'https://avs-primary-pre.101obex.mooo.com') {
            ReactGA.initialize('G-08SZKPG9V0');
            ReactGA.send(
                {
                    hitType: 'pageview',
                    page: window.location.pathname, 
                    title: '101OBEX, CORP' 
                }
            );
            //ReactGA.send('pageview')
        }

    }, []);


    const handleSignInClick = () => {
        // Aquí puedes realizar cualquier acción antes de redireccionar, si es necesario

        // Redirecciona a otra vista (por ejemplo, '/dashboard')
        history.push('/login');
    };

    const navbarRef = useRef(null);

    const handleClick = () => {
        // Llama a la función cleanMenuAll del componente BrowserNavbar
        if (navbarRef.current) {
            navbarRef.current.cleanMenuAll(); // O también navbarRef.current.cleanAll();
        }
    };




    return (
        <>


            <BrowserView>
            <BrowserNavbar  ref={navbarRef}/>
            
                <div className='d-flex flex-column' style={{ backgroundColor: '#fafafa' }}>

                    


                    <div id="usWrapper" className="w-100 pt-5"  onClick={() => { handleClick() }}>
                        <header className=" vh-100" style={{ backgroundImage: `url(${cabecera})` }}>
                            <div className="contentWrapper">
                                <Image src={cabecera2} className='reslogo' alt="Logo" />
                                <p className='txt-60-responsive txt-light text-center'>An <span className='textBold'>API Virtualization</span> Company</p>
                            </div>
                        </header>


                        <div className="container pb-8">
                            <div className="row">
                                <div className="col-md-6">
                                    <p className='txt-28 textBold'>Corporate Spirit ...</p>
                                    <p className='txt-20'>
                                        101OBEX, CORP stands out for its orderly, serious, and rigorous character,
                                        fundamental aspects that reflect our commitment to quality and excellence.
                                        As the owner, manufacturer, and marketer of AVAP technology,
                                        we maintain a solid and well-structured corporate approach,
                                        ensuring that every aspect of our operation meets the highest industry standards.
                                        Our dedication to precision and reliability is the cornerstone upon which we build long-lasting
                                        relationships with our clients and partners,
                                        guaranteeing robust and cutting-edge solutions in the realm of API lifecycle management.
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <p className='txt-28 textBold'>Startup Heart!</p>
                                    <p className='txt-20'>
                                        Alongside our strong corporate foundation, 101OBEX, CORP takes pride in its young and innovative
                                        spirit.
                                        We are a company that does not wait for changes to happen but actively drives them,
                                        with an entrepreneurial and bold mindset that propels us to explore new frontiers.
                                        With a daring vision and a proactive approach, our team is continually seeking opportunities
                                        to revolutionize the market and create innovative solutions that address the emerging needs of
                                        our clients,
                                        reaffirming our role as leaders in the technological evolution of the sector.
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className="w-100 bg-division">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className='txt-60 pt-7 text-center txt-light'>Our investors</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Image src={logo_framework_titulo}  className='w-100 pt-3 px-5 pb-5' alt="Logo" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container mt-5">
                            <div className="row">
                                <div className="col-md-12">
                                    <Image src={avap_logo} className='reslogo' alt="Logo" />
                                    <Image src={iconos_familia} className='logos' alt="Logo" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='txt-48 txt-light text-center mt-6'>An <span className='textBold'>API Virtualization</span> Platform</p>
                                    <p className='txt-28 txt-light text-center pb-6'>Empower your API lifecycle with AVAP: Unleashing the full potential of virtual API management for
                                        unparalleled efficiency and innovation.</p>
                                </div>
                            </div>
                        </div>

                            </div>

                        </div>


                <div>
                    <FooterMenuAccess green={true} />
                </div>
            </BrowserView>
            <MobileView>

                <MobileNavbar />

                {/* <Disclaimer /> */}

                <div id='mobileGateway' className="wrapper" >


                    <header className='w-100 headbanner mt-5' style={{ textAlign: 'center', height: '100vh', backgroundImage: `url(${cabecera})`, }} >
                    


                   {/*} <div style={{ width: '80%', textAlign: 'center' }}>*/}
                            <Image style={{ paddingTop: '20vh' }} src={cabecera2} width="300px" alt="Logo" />
                   {/*} </div >*/}




                    <div className='landingFont1' style={{ position: 'absolute', paddingTop: '1em', width: '100%', textAlign: 'center' }}>
                                        An <span className='textBold'>API Virtualization</span> Company
                    </div>
                    
                    </header>


                    <section style={{ marginTop: '4em' }} id='intro' className='container'>
 
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className='text-center hero-title'>
                                Corporate Spirit...
                                </h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className='bodyText text-center mb-5  mt-5'>
                                101OBEX, CORP stands out for its orderly, serious, and rigorous character, fundamental aspects that reflect our commitment to quality and excellence. As the owner, manufacturer, and marketer of AVAP technology, we maintain a solid and well-structured corporate approach, ensuring that every aspect of our operation meets the highest industry standards. Our dedication to precision and reliability is the cornerstone upon which we build long-lasting relationships with our clients and partners, guaranteeing robust and cutting-edge solutions in the realm of API lifecycle management.
                                </p>

                                
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-12">
                                <h1 className='text-center hero-title'>
                                Startup Heart
                                </h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className='bodyText text-center mb-5  mt-5'>
                                Alongside our strong corporate foundation, 101OBEX, CORP takes pride in its young and innovative spirit. We are a company that does not wait for changes to happen but actively drives them, with an entrepreneurial and bold mindset that propels us to explore new frontiers. With a daring vision and a proactive approach, our team is continually seeking opportunities to revolutionize the market and create innovative solutions that address the emerging needs of our clients, reaffirming our role as leaders in the technological evolution of the sector.
                                </p>

                                
                            </div>
                        </div>



                    </section>
                    <div style={{ backgroundColor: '#fafafa' }}>

                        <section style={{ width: '90vw', marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#fafafa' }}>

                            <div className="row" style={{ paddingTop: '2em' }}>
                            <div className="col-md-12">
                                <h1 className='text-center hero-title'>
                                Our Investors
                                </h1>
                            </div>
                        </div>


                    <div className="row">
                            <div className="col-md-12">
                                    <Image src={logo_framework_titulo}  className='logos' alt="Logo" />
                            </div>
                        </div>


                    </section>
</div>


                    <section style={{ textAlign: 'center', marginTop: '4em', marginBottom: '4em' }}>
<div>

                    <div style={{ width: '100%' }}>
                                <Image src={avap_logo} style={{
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    position: 'relative',
                                    width: '60%'
                                }} width="" alt="Logo" />
                            </div>


                    <div style={{ width: '100%' }}>
                                <Image src={iconos_familia} style={{
                                    marginTop: '00px',
                                    position: 'relative',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    width: '60%'
                                }} width="" alt="Logo" />
                            </div>

                            </div>




                        <div style={{ textAlign: 'center', backgroundColor: 'white', marginTop: '2em' }}>
                            <div style={{ width: '60%', marginLeft: 'auto', marginRight: 'auto' }}
                                className='landingFont2'
                            >
                                An <span className='textBold'>API Virtualization</span> Platform
                            </div>
                            <div
                                className='landingFont2'
                            >

                            </div>
                            <div
                                className='landingFont3'
                                style={{
                                    marginTop: '20px',
                                    width: '60%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                Empower your API lifecycle with AVAP: Unleashing the full potential of virtual API management for unparalleled efficiency and innovation.
                            </div>
                        </div>



                    </section>




                </div>


                <div>
                    <FooterMenuMobile green={true} />
                </div>




            </MobileView>
        </>
    )
}

export default NewLandingABOUT;